import { gql } from '@apollo/client';

export const GET_CURR_USER = gql`
  query getCurrUser {
    currUser {
      _id
      name
      state {
        activeGroup
      }
    }
  }
`;

export const LOGOUT = gql`
  mutation Logout {
    logout {
      success
    }
  }
`;
