import _ from 'lodash';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import * as Sentry from '@sentry/react';

// Data
import { getAuthUser } from '../../../lib/services/auth.service';

// Components
import NoGroupsPage from "../../_pages/NoGroupPage/no-group-page.component";
import ErrorPage from '../../_pages/ErrorPage/error-page.container';

interface RouteByRoleProps {
  publicPage?: any;
  authPage?: any;
  adminPage?: any;
  component?: any;
  title?: string;
  exact?: boolean;
  path: string;
  activeTab?: string;
  wrapper?: any;
}

const RoleByRoute: React.FC<RouteByRoleProps> = ({ wrapper, activeTab, publicPage, authPage, adminPage, component, title, ...rest })  => {
  const user = getAuthUser();

  console.log(user);
  let Component: any;
  if (user) {
    if (!_.get(user, 'state.activeGroup')) {
      Component = NoGroupsPage;
    } else if (_.get(user, 'isAdmin') && adminPage) {
      Component = adminPage;
    } else if (authPage) {
      Component = authPage;
    }
  }

  Component = Component || component;

  if (!Component && publicPage && user)
    return <Route render={() => <Redirect to="/activities" />} />;

  Component = Component || publicPage;

  if (!Component)
    return <Route render={() => <Redirect to="/" />} />;

  function syncLogout(event: StorageEvent) {
    if (event.key === 'logout') {
      window.location.href = '/';
    }
  }

  document.title = title || 'NorWest - Hans Kai'

  window.removeEventListener('storage', syncLogout);
  window.addEventListener('storage', syncLogout);

  const Wrapper: any = wrapper;

  return (
    <Route
      {...rest}
      render={props => (
        <Sentry.ErrorBoundary fallback={ErrorPage}>
          {wrapper
            ? <Wrapper activeTab={activeTab}><Component {...props} /></Wrapper>
            : <Component {...props} />
          }
        </Sentry.ErrorBoundary>
      )}
    />
  );
};

export default RoleByRoute;
