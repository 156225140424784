import { defineMessages } from 'react-intl';

export const scope = 'app.components.MemberInvite';

export default defineMessages({
  labelEmail: {
    id: `${scope}.labelEmail`,
    defaultMessage: 'Email',
  },
  actionInvite: {
    id: `${scope}.submit`,
    defaultMessage: 'Send Invite',
  },
});
