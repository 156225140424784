import { gql } from '@apollo/client';
import { handleCreateMutation, handleDeleteMutation, handleUpdateMutation } from '../helpers/mutation.helper';

export const GET_ACTIVITY = gql`
  query GetActivity($_id: ID) {
    activity(_id: $_id) {
      _id
      type
      duration
      user {
        _id
        name
        email
      }
      messages {
        content
        user {
          _id
          name
          email
        }
        sentAt
      }
      date
      timeOfDay
      isPublic
    }
  }
`;

export const GET_ACTIVITIES = gql`
  query GetActivities($startDate: String!, $groupId: ID) {
    activities(startDate: $startDate, groupId: $groupId) {
      _id
      type
      duration
      user {
        _id
        name
        email
      }
      unreadMsg
      totalMsg
      date
      timeOfDay
      isPublic
    }
  }
`;

export const ADD_ACTIVITY = gql`
  mutation AddActivity($activityData: ActivityInput!) {
    createActivity(activityData: $activityData) {
      _id
      type
      duration
      user {
        _id
        name
        email
      }
      unreadMsg
      totalMsg
      date
      timeOfDay
      isPublic
    }
  }
`;

// @ts-ignore
export const handleActivityCreation = ({ mutationResponse, ...rest }) => {
  // @ts-ignore
  return handleCreateMutation({
    ...rest,
    mutationResponse: { __typename: 'Activity', ...mutationResponse },
    mutationResponseRef: 'createActivity',
    cacheQuery: GET_ACTIVITIES,
    cacheQueryRef: 'activities',
  });
};

export const UPDATE_ACTIVITY = gql`
  mutation UpdateActivity($_id: ID!, $activityData: ActivityInput!) {
    updateActivity(_id: $_id, activityData: $activityData) {
      _id
      type
      duration
      user {
        _id
        name
        email
      }
      unreadMsg
      totalMsg
      date
      timeOfDay
      isPublic
    }
  }
`;

// @ts-ignore
export const handleActivityUpdate = ({ mutationResponse, ...rest }) => {
// @ts-ignore
  return handleUpdateMutation({
    ...rest,
    mutationResponse: { __typename: 'Activity', ...mutationResponse },
    mutationResponseRef: 'updateActivity',
  });
};

export const REMOVE_ACTIVITY = gql`
  mutation RemoveActivity($_id: ID!) {
    removeActivity(_id: $_id) {
      _id
    }
  }
`;

// @ts-ignore
export const handleActivityDeletion = ({ mutationResponse, ...rest }) => {
  // @ts-ignore
  return handleDeleteMutation({
    ...rest,
    mutationResponse: { __typename: 'Activity', ...mutationResponse },
    mutationResponseRef: 'removeActivity',
    cacheQuery: GET_ACTIVITIES,
    cacheQueryRef: 'activities',
  });
};

export const GET_ACTIVITY_WEEK_SUMMARY = gql`
  query GetActivitySummaryByWeek($startDate: String!, $groupId: ID) {
    activitySummaryByWeek(startDate: $startDate, groupId: $groupId) {
      goal
      g
      p
    }
  }
`;
