import { useMutation } from '@apollo/client';

import { handleDeleteMutation } from '../helpers/mutation.helper';
import { GET_CHECKINS, REMOVE_CHECKIN } from '../queries/check-in.queries';


export const buildRemoveCheckIn = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [removeCheckIn] = useMutation(REMOVE_CHECKIN);

  return (_id: string) =>
    // @ts-ignore
    handleDeleteMutation({
      mutation: removeCheckIn,
      mutationVariables: { _id },
      mutationResponseRef: 'removeCheckIn',
      cacheQuery: GET_CHECKINS,
      cacheQueryRef: 'checkIns',
    });
};
