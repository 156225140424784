import React from 'react';
import { Form, Field } from 'formik';

// Material UI
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';

// Data
import messages from './forgot-password-form.messages';
import { ForgotPasswordFormProps } from './forgot-password-form.interface';

// Components
import IntlMsg from '../../_core/IntlMsg/intl-msg.component';
import { PrimaryButton } from '../../_core/_ui/buttons.component';
import { FormHelperText } from '../../_core/_ui/forms.component';
import FormikInput from '../../_forms/Formik/formik-input.component';

const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = ({ form, isSubmitted, submitError }) => {
  const { isSubmitting, isValid } = form;

  if (isSubmitted) return (
    <Box display="flex" flexDirection="column" textAlign="center">
      <Typography key="submitted">A message has been sent been sent. Please follow the instructions there to reset your password</Typography>
    </Box>
  );

  return (
    <Box maxWidth="600px">
      <Form>
        <Typography variant="h4" component="h1" gutterBottom>Reset your password</Typography>
        <Typography gutterBottom>Enter the email address or phone number associated with your account and we&#8217;ll send you a link to reset your password.</Typography>

        <Field fid="fpf" name="username" component={FormikInput} />

        <FormControl margin="dense">
          <Box display="flex" flexDirection="row">
            <PrimaryButton disabled={isSubmitting || !isValid} type="submit">
              {isSubmitting
                ? <IntlMsg {...messages.btnSubmitting} />
                : <span><IntlMsg {...messages.btnSubmit} /></span>
              }
            </PrimaryButton>
          </Box>
        </FormControl>

        {submitError && <FormHelperText error>{submitError}</FormHelperText>}
      </Form>
    </Box>
  );
};

export default ForgotPasswordForm;
