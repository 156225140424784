import { withStyles } from '@material-ui/core/styles';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

export const ListItemTertiaryAction = withStyles(theme => ({
  root: {
    right: '76px'
  },
}))(ListItemSecondaryAction);

export default ListItemTertiaryAction;
