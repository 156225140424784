import _ from 'lodash';
import { useMutation, useSubscription } from '@apollo/client';

// Queries
import { MARK_CONVERSATION_READ, MESSAGE_FIELDS, NEW_MESSAGE_SUBSCRIPTION } from '../queries/message.queries';
import { getAuthUser } from './auth.service';
import { getCacheModel } from '../helpers/mutation.helper';

interface SubscribeToMessagesProps {
  entity?: string;
  parentId?: string;
  onMarkConversationRead?: Function;
}

export const subscribeToMessages  = ({ entity, parentId, onMarkConversationRead }: SubscribeToMessagesProps) => {
  const user = getAuthUser();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useSubscription(NEW_MESSAGE_SUBSCRIPTION, {
    skip: !parentId || !entity || !user,
    variables: { entity, parentId, userId: _.get(user, '_id') },
    onSubscriptionData: ({ client, subscriptionData }) => {
      const { cache } = client;
      const message = _.get(subscriptionData, 'data.messageAdded.message');
      const model = getCacheModel(cache, entity, parentId);

      cache.modify({
        id: cache.identify(model),
        fields: {
          unreadMsg(count = 0) {
            return count + 1;
          },

          messages(existingRefs = [], {readField}) {
            let createdRef = cache.writeFragment({ data: message, fragment: MESSAGE_FIELDS });

            if (existingRefs.some((ref: any) => readField('_id', ref) === message._id)) {
              return existingRefs;
            }

            if (onMarkConversationRead) {
              onMarkConversationRead(entity, parentId);
            }

            return [createdRef, ...existingRefs];
          }
        }
      });
    }
  });
};

export const buildMarkConversationRead = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [markRead] = useMutation(MARK_CONVERSATION_READ);

  return (entity: string, parentId: string) => markRead({
    variables: { entity, parentId },
    update: (cache) => {
      const model = getCacheModel(cache, entity, parentId);

      cache.modify({
        id: cache.identify(model),
        fields: {
          unreadMsg() {
            return 0;
          }
        }
      });
    }
  });
};
