import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const ColourPrimaryButton = withStyles(theme => ({
  root: {
    color: '#fff',
    background: '#64CBBA',
    boxShadow: 'none',
    border: '1px solid #fff',
    '&:hover': {
      background: '#54BBAA',
    },
    padding: '8px 16px',
    maxWidth: '180px',
  },
}))(Button);

export default ColourPrimaryButton;
