import { gql } from '@apollo/client';
import { handleCreateMutation, handleDeleteMutation, handleUpdateMutation } from '../helpers/mutation.helper';

export const GET_MEAL = gql`
  query GetMeal($_id: ID) {
    meal(_id: $_id) {
      _id
      type
      name
      user {
        _id
        name
      }
      messages {
        content
        user {
          _id
          name
        }
        sentAt
      }
      date
      isPublic
    }
  }
`;

export const GET_MEALS = gql`
  query GetMeals($startDate: String!, $groupId: ID) {
    meals(startDate: $startDate, groupId: $groupId) {
      _id
      type
      name
      user {
        _id
        name
      }
      unreadMsg
      totalMsg
      date
      isPublic
    }
  }
`;

export const ADD_MEAL = gql`
  mutation AddMeal($mealData: MealInput!) {
    createMeal(mealData: $mealData) {
      _id
      type
      name
      user {
        _id
        name
      }
      unreadMsg
      totalMsg
      date
      isPublic
    }
  }
`;

// @ts-ignore
export const handleMealCreation = ({ mutationResponse, ...rest }) => {
  // @ts-ignore
  handleCreateMutation({
    ...rest,
    mutationResponse: { __typename: 'Meal', ...mutationResponse },
    mutationResponseRef: 'createMeal',
    cacheQuery: GET_MEALS,
    cacheQueryRef: 'meals',
  });
};

export const REMOVE_MEAL = gql`
  mutation RemoveMeal($_id: ID!) {
    removeMeal(_id: $_id) {
      _id
    }
  }
`;

export const UPDATE_MEAL = gql`
  mutation UpdateMeal($_id: ID!, $mealData: MealInput!) {
    updateMeal(_id: $_id, mealData: $mealData) {
      _id
      type
      name
      user {
        _id
        name
      }
      date
      isPublic
    }
  }
`;

// @ts-ignore
export const handleMealUpdate = ({ mutationResponse, ...rest }) => {
// @ts-ignore
  handleUpdateMutation({
    ...rest,
    mutationResponse: { __typename: 'Meal', ...mutationResponse },
    mutationResponseRef: 'updateMeal',
  });
};
// @ts-ignore
export const handleMealDeletion = ({ mutationResponse, ...rest }) => {
  // @ts-ignore
  handleDeleteMutation({
    ...rest,
    mutationResponse: { __typename: 'Meal', ...mutationResponse },
    mutationResponseRef: 'removeMeal',
    cacheQuery: GET_MEALS,
    cacheQueryRef: 'meals',
  });
};
