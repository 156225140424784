import React from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';

// Material UI
import withStyles from '@material-ui/core/styles/withStyles';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import NextIcon from '@material-ui/icons/ArrowForwardIos';
import PrevIcon from '@material-ui/icons/ArrowBackIos';
import TodayIcon from '@material-ui/icons/Today';

// Data
import { DATE_FORMAT } from '../../../lib/constants';

// Components
const RangeDate = styled.div`
  flex-grow: 1;
  text-align: center;
  color: rgba(255, 255, 255, 0.86);
`;

const RangeButton = withStyles({
  root: {
    color: 'rgba(255, 255, 255, 0.56)'
  }
})(IconButton);

interface DateRangeControlProps {
  onChangeWeek: Function;
  startDate: string;
  endDate: string;
  isThisWeek: boolean;
}

const DateRangeControl: React.FC<DateRangeControlProps> = ({ onChangeWeek, startDate, endDate, isThisWeek }) => {
  return (
    <Box display="flex" alignItems="center">
      <RangeButton className="Mui-highlight-disabled" disabled={isThisWeek} onClick={() => onChangeWeek('current')}><TodayIcon /></RangeButton>
      <RangeButton onClick={() => onChangeWeek('prev')}><PrevIcon /></RangeButton>
      <RangeDate>
        {dayjs(startDate, DATE_FORMAT).format('MMM. DD')} - {dayjs(endDate, DATE_FORMAT).format('DD')}
      </RangeDate>
      <RangeButton onClick={() => onChangeWeek('next')}><NextIcon /></RangeButton>
    </Box>
  );
};

export default DateRangeControl;
