import React, { ReactNode } from 'react';
import styled from 'styled-components';

// Material UI
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

interface CardLayoutProps {
  children: ReactNode;
  maxWidth: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined;
  heading?: string;
}

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
`;

const CardLayout: React.FC<CardLayoutProps> = ({ children, maxWidth, heading }) => {
  return (
    <Wrapper>
      <Container maxWidth={maxWidth} style={{ height: '100%' }}>
        <Box py={8} px={2}>
          {heading && <h1>{heading}</h1>}
          {children}
        </Box>
      </Container>
    </Wrapper>
  );
};

export default CardLayout;
