import React from 'react';
import dayjs from 'dayjs';

// Data
import { DATE_FORMAT } from '../../../lib/constants';

// Components
import DateRangeControl from './date-range-control.component';
import { getActiveWeek } from '../../../lib/services/app.service';
import { activeWeek } from '../../../lib/queries/app.queries';

const DateRangeControlContainer = () => {
  const startDate = getActiveWeek();

  const endDate = dayjs(startDate, DATE_FORMAT).add(6, 'day').format('YYYY-MM-DD');
  const today = dayjs().format('YYYY-MM-DD')
  const isThisWeek = today >= startDate && today <= endDate;

  const onChangeWeek = (nav: string) => {
    let nextStartDate;

    if (nav === 'next') {
      nextStartDate = dayjs(startDate, DATE_FORMAT).add(7, 'day').format(DATE_FORMAT)
    } else if (nav === 'prev') {
      nextStartDate = dayjs(startDate, DATE_FORMAT).subtract(7, 'day').format(DATE_FORMAT)
    } else {
      nextStartDate = dayjs().startOf('week').format(DATE_FORMAT)
    }

    activeWeek(nextStartDate);
  }

  return (
    <DateRangeControl onChangeWeek={onChangeWeek} startDate={startDate} endDate={endDate} isThisWeek={isThisWeek} />
  );
};

DateRangeControlContainer.propTypes = {

};

export default DateRangeControlContainer;
