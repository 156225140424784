import { useReactiveVar } from '@apollo/client';

import { activeWeek, createVisibility, editModel, feedFilter } from '../queries/app.queries';
import { FILTERS } from '../constants';

export const buildSwitchFilterType = () => {
  const filter = getFeedFilter();

  return () => feedFilter(filter === FILTERS.GROUP ? FILTERS.USER : FILTERS.GROUP);
};

export const getFeedFilter = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useReactiveVar(feedFilter);
};

export const getActiveWeek = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useReactiveVar(activeWeek);
};

export const getCreateVisibility = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useReactiveVar(createVisibility);
};

export const getEditModel = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useReactiveVar(editModel);
};
