import React from 'react';
import styled from 'styled-components';

// Material UI
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

// Data
import { authUser, getAuthUser } from '../../lib/services/auth.service';

// Components
const Wrapper = styled.div`
  color: rgba(255, 255, 255, 0.86);
  font-weight: lighter;
  padding: 0 24px;
`;

const NotificationsPageContainer: React.FC = () => {
  const user = getAuthUser();

  const handleSetNotificationType = (e: any) => {
    // Update local user
    if (user) {
      const nextUser = { ...user };
      nextUser.state = { ...nextUser.state, notificationType: e.target.value};
      authUser(nextUser);
    }
  };

  return (
    <Wrapper>
      <Card>
        <CardContent>
          <Typography gutterBottom variant="h6">Receive Notifications</Typography>
          <FormControl component="fieldset">
            <RadioGroup aria-label="gender" name="gender1" value={user?.state.notificationType || 'every'} onChange={handleSetNotificationType}>
              <FormControlLabel value="never" control={<Radio />} label="Never" />
              <FormControlLabel value="every" control={<Radio />} label="For every message received" />
              <FormControlLabel value="after10" control={<Radio />} label="After 10 missed messages" />
            </RadioGroup>
          </FormControl>
        </CardContent>
      </Card>
    </Wrapper>
  );
}

export default NotificationsPageContainer;
