import React from 'react';
import styled from 'styled-components';

// Components
import ForgotPasswordFormContainer from '../../../user/ForgotPasswordForm/forgot-password-form.container';
import SingleFormLayout from '../../_layout/SingleFormLayout/single-form.layout';

const Wrapper = styled.div`
  background: #eee;
  height: 100%;
`;

const ForgotPasswordPage: React.FC = () =>
  <Wrapper>
    <SingleFormLayout>
      <ForgotPasswordFormContainer />
    </SingleFormLayout>
  </Wrapper>;

export default ForgotPasswordPage;
