import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

// Material UI
import Button from '@material-ui/core/Button';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import withStyles from '@material-ui/core/styles/withStyles';
import Fab from '@material-ui/core/Fab';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';

// Components
import messages from './bottom-menu.messages';
import CreateActivityContainer from '../../../containers/activity/CreateActivityContainer/create-activity.container';
import CreateMealContainer from '../../../containers/meal/CreateMealContainer/create-meal.container';
import { createVisibility, editModel } from '../../../lib/queries/app.queries';
import { getCreateVisibility, getEditModel } from '../../../lib/services/app.service';
import { buildMarkConversationRead } from '../../../lib/services/message.service';
import { ENTITIES } from '../../../lib/constants';

const FixedBottomNav = withStyles({
  root: {
    background: '#fafafa',
    borderTop: '1px solid #EDF0F2',
    position: 'fixed',
    left: '50%',
    maxWidth: '1280px',
    transform: 'translateX(-50%)',
    bottom: 0,
    width: '100%',
    zIndex: 1200,
    height: 'calc(env(safe-area-inset-bottom) + 56px)',
    label: {
      display: 'none',
    },
    '& > button': {
      height: '56px',
    },
  }
})(BottomNavigation);

const RecordButton = withStyles({
  root: {
    boxShadow: 'none',
    background: '#64CBBA',
    border: '4px solid #EDF0F2',
    position: 'relative',
    top: '-28px',
    color: 'rgba(255, 255, 255, 0.86)',
    zIndex: 1050,
    flexShrink: 0,
    '&:hover': {
      background: '#64CBBA'
    },
    transitionProperty: 'top',
    transitionDuration: '0.2s',
    transitionTimingFunction: 'ease-in-out'
  }
})(Fab);

const BottomNavAction = withStyles({
  root: {
    paddingTop: '16px !important',
  },
  label: {
    visibility: 'hidden',
  },
  selected: {
    color: '#64CBBA !important',
  },
})(BottomNavigationAction);

interface SlideUpMenuProps {
  isShown: boolean;
}

const SlideUpMenu = styled.div<SlideUpMenuProps>`
  min-height: 56px;
  background: #eee;
  display: flex;
  justify-content: center;
  position: absolute;
  z-index: 1040;
  width: 100%;
  box-sizing: content-box;
  padding-bottom: max(0px, env(safe-area-inset-bottom));
  top: ${props => props.isShown ? '0px' : 'calc(env(safe-area-inset-bottom) + 56px)'};
  transition: all 0.2s ease 0s;
`;

const CreateButton = withStyles({
  root: {
    flexGrow: 1,
    maxWidth: '180px',
    minWidth: '100px',
  },
})(Button);

interface CreatePageWrapperProps {
  isShown: boolean
}

const CreatePageWrapper = styled.div<CreatePageWrapperProps>`
  background: #eee;
  height: 100%;
  width: 100%;
  transition: all 0.2s ease 0s;
  position: fixed;
  max-width: 1280px;
  left: 50%;
  transform: translateX(-50%);
  top: ${props => props.isShown ? '0px' : '2200px'};
  z-index: 11000;
  padding: 16px;
  overflow-y: auto;
`;

const IconWrapper = styled.div` position: relative; `;

const NotificationNum = styled.div`
  position: absolute;
  top: -10px;
  background: #64CBBA;
  color: rgba(255, 255, 255, 0.86);
  border-radius: 40px;
  padding: 2px 4px;
  right: -14px;
`;

interface BottomMenuProps {
  activeTab: string;
  onChangeTab: Function;
  group?: IGroup | null;
}

const BottomMenu: React.FC<BottomMenuProps> = ({ activeTab, onChangeTab, group }) => {
  const onMarkConversationRead = buildMarkConversationRead();
  const currCreateVisible = getCreateVisibility();
  const currEditModel = getEditModel();

  const [state, setState] = React.useState({ showCreateOptions: false });
  const { showCreateOptions } = state;

  function cancelCreate() {
    setState({ showCreateOptions: false });
    createVisibility('none');
    editModel('none');
  }

  return (
    <div>
      <FixedBottomNav
        value={activeTab}
        onChange={(event, newValue) => {
          if (newValue === 'chat' && group) onMarkConversationRead(ENTITIES.GROUP, group._id);
          if (newValue) onChangeTab(newValue);
      }}>
        <BottomNavAction value="chat" icon={
          <IconWrapper>
            <QuestionAnswerIcon />
            {activeTab !== 'chat' && group && Number(group.unreadMsg) > 0 && <NotificationNum>{group.unreadMsg}</NotificationNum>}
          </IconWrapper>}
        />
        <BottomNavAction value="check-in" icon={<AssignmentTurnedInIcon />} />
        <RecordButton
          style={{ top: activeTab === 'chat' ? 0 : '-28px' }}
          onClick={() => setState({ showCreateOptions: !showCreateOptions })}
        >
          { showCreateOptions ? <CloseIcon /> : <EditIcon />}
        </RecordButton>
        <BottomNavAction value="meals" icon={<RestaurantIcon />} />
        <BottomNavAction value="activities" icon={<DirectionsRunIcon />} />
        <SlideUpMenu isShown={showCreateOptions}>
          <CreateButton onClick={() => createVisibility('meals')}>
            <FormattedMessage {...messages.buttonAddMeal} />
          </CreateButton>
          <CreateButton onClick={() => createVisibility('activities')}>
            <FormattedMessage {...messages.buttonAddActivity} />
          </CreateButton>
        </SlideUpMenu>
      </FixedBottomNav>
      <CreatePageWrapper isShown={currCreateVisible === 'meals'}>
        <CreateMealContainer isShown={currCreateVisible === 'meals'} onCancel={cancelCreate} modelId={currEditModel === 'none' ? undefined : currEditModel} />
      </CreatePageWrapper>
      <CreatePageWrapper isShown={currCreateVisible === 'activities'}>
        <CreateActivityContainer isShown={currCreateVisible === 'activities'} onCancel={cancelCreate} modelId={currEditModel === 'none' ? undefined : currEditModel} />
      </CreatePageWrapper>
    </div>
  );
};

export default BottomMenu;
