import React from 'react';

// Data
import { buildCancelInvite, buildResendInvite } from '../../../lib/services/invite.service';

// Components
import InviteList from './invite-list.component';

interface InviteListContainerProps {
  group: IGroup;
  invites: Array<IInvite>;
}

const InviteListContainer: React.FC<InviteListContainerProps> = ({ group, invites }) => {
  const onResendInvite = buildResendInvite();
  const onCancelInvite = buildCancelInvite(group);

  return <InviteList invites={invites} onResendInvite={onResendInvite} onCancelInvite={onCancelInvite} />;
};

export default InviteListContainer;
