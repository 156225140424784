import _ from 'lodash';
import { useFormik } from 'formik';
import React from 'react';
import { useMutation } from '@apollo/client';

// Data
import { ADD_GROUP, handleGroupCreation } from '../../../lib/queries/group.queries';

// Components
import CreateGroupForm from '../../../components/group/CreateGroupForm/create-group-form.component';
import {
  CreateGroupFormValues
} from '../../../components/group/CreateGroupForm/create-group-form.interfaces';
import CreateGroup from '../../../components/group/CreateGroup/create-group.component';
import { object, string } from 'yup';

const INITIAL_FORM_VALUES = { name: '' };

interface CreateSetContainerProps {

}

const CreateGroupContainer: React.FC<CreateSetContainerProps> = () => {
  const [addGroup] = useMutation(ADD_GROUP);

  function handleSubmit(values: CreateGroupFormValues, { setSubmitting, resetForm }: any) {
    const groupData = _.pick(values, ['name']);

    handleGroupCreation({
      mutation: addGroup,
      mutationVariables: { groupData },
      mutationResponse: { ...groupData },
      cacheQueryVariables: { },
    });

    setSubmitting(false);
    resetForm({ name: '' });
  }

  const validationSchema = object().shape({
    name: string().required('Required'),
  });

  const formik = useFormik({
    initialValues: INITIAL_FORM_VALUES,
    onSubmit: handleSubmit,
    validationSchema
  });

  const createGroupForm = <CreateGroupForm {...{ formik }} />;

  return <CreateGroup {...{ createGroupForm }} />;
};

export default CreateGroupContainer;
