import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useFormik } from 'formik';

// Data
import {
  SET_MEMBER_ROLE,
  handleMemberUpdate,
} from '../../../lib/queries/member.queries';
import { buildRemoveMember } from '../../../lib/services/member.service';
import { buildSendInvite } from '../../../lib/services/invite.service';

// Components
import MemberList from '../../../components/member/MemberList/member-list.component';
import MemberInvite from '../../../components/member/MemberInvite/member-invite.component';
import InviteListContainer from '../../../components/invite/InviteList/invite-list.container';

const INITIAL_FORM_VALUES: InviteMemberVariables = {
  email: '',
  phone: ''
};

interface ManageMembersContainerProps {
  group: IGroup;
}

interface InviteMemberVariables {
  email?: string | undefined | null;
  phone?: string | undefined | null;
}

const ManageMembersContainer: React.FC<ManageMembersContainerProps> = ({ group }) => {
  const [setMemberRole] = useMutation(SET_MEMBER_ROLE);
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [showSaved, setShowSaved] = useState(false);

  const { removeMember } = buildRemoveMember(group);
  const { sendInvite } = buildSendInvite(group);

  const onRemoveMember = (member: IMember) => {
    removeMember(member._id);
  };

  const onChangeRole = (member: IMember, role: string) => {
    handleMemberUpdate({
      mutation: setMemberRole,
      mutationVariables: { _id: member._id, role },
      mutationResponse: { ...member, role },
    });
  };

  const onInviteMember = (values: InviteMemberVariables, { setSubmitting }: any) => {
    setSubmitError(null);
    setShowSaved(false);

    sendInvite({ email: values.email, phone: values.phone })
      .then(() => {
        setSubmitting(false);

        setShowSaved(true);
        setTimeout(() => {
          setShowSaved(false);
        }, 3000);
      })
      .catch(err => {
        if (err) setSubmitError(err.message);
        setSubmitting(false);
        setTimeout(() => {
          setSubmitError(null);
        }, 3000);
      })
  };

  const formik = useFormik({
    initialValues: INITIAL_FORM_VALUES,
    onSubmit: onInviteMember,
  });

  const invites: Array<IInvite> = group.invites || [];

  return (
    <div>
      <h2>Members</h2>
      <MemberList
        members={group.members}
        onRemoveMember={onRemoveMember}
        onChangeRole={onChangeRole}
      />
      <h2>Invites</h2>
      <InviteListContainer invites={invites} group={group} />
      <MemberInvite formik={formik} submitError={submitError} showSaved={showSaved} />
    </div>
  );
};

export default ManageMembersContainer;
