import _ from 'lodash';
import React from 'react';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';

// Material UI
import Box from '@material-ui/core/Box';

// Data
import { GET_ACTIVITIES } from '../../lib/queries/activity.queries';
import { FILTERS } from '../../lib/constants';

// Components
import ActivityList from '../../components/activity/ActivityList/activity-list.component';
import ActivityChart from '../../components/activity/ActivityChart/activity-chart.component';
import { getActiveWeek, getFeedFilter } from '../../lib/services/app.service';
import { getActivitySummaryByWeek, getRefetchData, refetchData } from '../../lib/services/activity.service';
import DateRangeControlContainer from '../../components/misc/DateRangeControl/date-range-control.container';
import { getAuthUser } from '../../lib/services/auth.service';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

interface LegendBoxInterface {
  color: string;
}

const LegendBox = styled.div<LegendBoxInterface>`
  background-color: ${props => props.color};
  width: 12px;
  height: 12px;
  display: block;
  margin: 2px 8px 2px 0;
`;

const LegendText = styled.div`
  font-size: 12px;
  margin-right: 8px;
`;

interface GetActivitiesVariables {
  userId?: string;
  groupId?: string;
  startDate: string;
}

interface GetActivitiesOptions {
  variables: GetActivitiesVariables;
  skip: boolean;
  pollInterval?: number;
  fetchPolicy: any;
}

const ActivityPageContainer: React.FC = () => {
  const [hasLoaded, setHasLoaded] = React.useState(false);

  const shouldRefetch = getRefetchData();

  const user = getAuthUser();

  const filter = getFeedFilter();
  const startDate = getActiveWeek();

  // Get activities...
  const options: GetActivitiesOptions = {
    variables: { startDate },
    skip: false,
    pollInterval: 10 * 60 * 1000,
    fetchPolicy: 'cache-and-network'
  };

  if (user && filter === FILTERS.GROUP) {
    options.variables.groupId = _.get(user, 'state.activeGroup');
  } else if (filter === FILTERS.GROUP) {
    options.skip = true;
  }

  const {loading, error, data, refetch } = useQuery(GET_ACTIVITIES, options);
  if (!loading && !hasLoaded) setHasLoaded(true);

  if (shouldRefetch) {
    refetch(options.variables);
    refetchData(false);
  }

  const activities = _.get(data, 'activities', []);

  const { summary, loading: chartLoading, error: chartError } = getActivitySummaryByWeek(startDate, filter === FILTERS.GROUP ? _.get(user, 'state.activeGroup') : null);

  return (
    <Wrapper>
      <Box mb={2} ml={2} display="flex" flexDirection="row" justifyContent="flex-start">
        <LegendBox color="#3D8980" /><LegendText>{filter === FILTERS.GROUP ? 'You' : 'Active Minutes'}</LegendText>
        {filter === FILTERS.GROUP && <><LegendBox color="#9EDFD7" /><LegendText>Group Average</LegendText></>}
      </Box>
      <ActivityChart loading={chartLoading} error={chartError} data={summary} filter={filter} />
      <Box ml={2} mr={1}>
        <DateRangeControlContainer />
      </Box>
      <ActivityList {...{activities, loading: !hasLoaded, error, filter, user}} />
    </Wrapper>
  );
}

export default ActivityPageContainer;
