import React from 'react';
import styled from 'styled-components';

// Material UI
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

// Data
import logo from '../../../assets/logo.png';
import logo2x from '../../../assets/logo@2x.png';

const Logo = styled.img`
  margin: 40px 0;
  width: 400px;
`;

const Text = styled.p`
  color: rgba(0, 0, 0, 0.56);
  text-align: center;
`;

const LoadingPage = () => (
  <Container style={{ background: '#fefefe', height: '100%' }}>
    <Grid container spacing={3} justify="center" alignItems="center">
      <Grid container item xs={12} justify="center" alignItems="center">
        <Logo srcSet={`${logo} 1x, ${logo2x} 2x`} alt="NorWest Coop" />
      </Grid>
      <Grid item xs={12}>
        <Text>Loading...</Text>
      </Grid>
    </Grid>
  </Container>
);

export default LoadingPage;
