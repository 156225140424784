import _ from 'lodash';
import { makeVar, useQuery, useReactiveVar } from '@apollo/client';

// Queries
import { GET_ACTIVITY_WEEK_SUMMARY } from '../queries/activity.queries';

export const refetchData = makeVar(false);
// eslint-disable-next-line react-hooks/rules-of-hooks
export const getRefetchData = () => useReactiveVar(refetchData);

export const getActivitySummaryByWeek = (startDate: string, groupId?: string | null) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data, ...rest } = useQuery(GET_ACTIVITY_WEEK_SUMMARY, {
    variables: { startDate, groupId },
    fetchPolicy: 'cache-and-network',
    pollInterval: 10 * 60 * 1000,
  });

  return { ...rest, summary: _.get(data, 'activitySummaryByWeek', {}) };
}
