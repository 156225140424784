import _ from 'lodash';
import React from 'react';
import { convertMinutesToMessage } from '../../../lib/helpers/duration.helper';
import { FormattedMessage } from 'react-intl';
import { useMutation } from '@apollo/client';

// Material UI
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import BackIcon from '@material-ui/icons/ChevronLeft';
import MessageIcon from '@material-ui/icons/Forum';

// Data
import { ENTITIES, FILTERS } from '../../../lib/constants';
import { handleActivityDeletion, REMOVE_ACTIVITY } from '../../../lib/queries/activity.queries';

// Components
import { ListItemCard, ListItemCardContent } from '../../misc/ListItemCard/list-item-card.component';
import ConversationContainer from '../../../containers/conversation/ConversationContainer/conversation.container';
import ActionsWrapper from '../../conversation/actions-wrapper.component';
import BackWrapper from '../../conversation/back-wrapper.component';
import HeaderContent from '../../conversation/header-content.component';
import { PrimaryContent, SecondaryContent } from '../../conversation/content.component';
import { IconSets, IconCount } from '../../conversation/icon-sets.component';

// Content
import messages from '../CreateActivityForm/create-activity-form.messages';
import { getActiveWeek, getFeedFilter } from '../../../lib/services/app.service';
import { createVisibility, editModel } from '../../../lib/queries/app.queries';
import { buildMarkConversationRead, subscribeToMessages } from '../../../lib/services/message.service';

interface ActivityListItemProps {
  activity: IActivity;
  user?: IUser | null;
}

const ActivityListItem: React.FC<ActivityListItemProps> = ({ activity, user }) => {
  subscribeToMessages({ entity: ENTITIES.ACTIVITY, parentId: activity._id });

  const [{ isOpen }, setState] = React.useState({ isOpen: false });
  const [removeActivity] = useMutation(REMOVE_ACTIVITY);

  const startDate = getActiveWeek();

  // @ts-ignore
  const type = messages[`label${activity.type}`];
  // @ts-ignore
  const timeOfDay = messages[`label${activity.timeOfDay}`];

  // Get feed filter...
  const filter = getFeedFilter();

  function initiateDelete() {
    if (window.confirm('Are you sure you want to remove this activity?')) {
      const cacheQueryVariables: any = filter === FILTERS.GROUP ? {groupId: _.get(user, 'state.activeGroup') } : {};
      cacheQueryVariables.startDate = startDate;

      handleActivityDeletion({
        mutation: removeActivity,
        mutationVariables: {_id: activity._id},
        mutationResponse: { _id: activity._id },
        cacheQueryVariables,
      });
    }
  }

  function initiateEdit(e: any) {
    e.stopPropagation();

    createVisibility('activities');
    editModel(activity._id);
  }

  const onMarkConversationRead = buildMarkConversationRead();
  function onOpenConversation() {
    onMarkConversationRead(ENTITIES.ACTIVITY, activity._id);
    setState({ isOpen: !isOpen });
  }

  // @ts-ignore
  return (
    <ListItemCard className={isOpen ? 'active' : ''}>
      <ListItemCardContent isOpen={isOpen} onClick={onOpenConversation}>
        {isOpen && <BackWrapper><BackIcon /></BackWrapper>}
        <HeaderContent isOpen={isOpen}>
          <PrimaryContent>{`${convertMinutesToMessage(activity.duration)} of `}<FormattedMessage {...type} /></PrimaryContent>
          <SecondaryContent small={isOpen}>{activity.user.name} · <FormattedMessage {...timeOfDay} /></SecondaryContent>
          <IconSets>
            {!isOpen && activity.totalMsg > 0 && <IconCount unread={activity.unreadMsg > 0}><MessageIcon /><div>{activity.totalMsg}</div></IconCount>}
          </IconSets>
        </HeaderContent>
        {user && activity.user._id === user._id && isOpen && (
          <ActionsWrapper>
            <IconButton onClick={initiateEdit}><EditIcon /></IconButton>
            <IconButton onClick={initiateDelete}><DeleteIcon /></IconButton>
          </ActionsWrapper>
        )}
      </ListItemCardContent>
      {isOpen && <ConversationContainer type="activity" isPublic={activity.isPublic} parentId={activity._id} entity="Activity" />}
    </ListItemCard>
  );
};

export default ActivityListItem;
