import { defineMessages } from 'react-intl';

export const scope = 'app.components.CreateActivityForm';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'New Meal',
  },
  labelName: {
    id: `${scope}.labelName`,
    defaultMessage: 'Food'
  },
  labelDescription: {
    id: `${scope}.labelDescription`,
    defaultMessage: 'Description'
  },
  labelBREAKFAST: {
    id: `${scope}.labelBREAKFAST`,
    defaultMessage: 'Breakfast',
  },
  labelLUNCH: {
    id: `${scope}.labelLUNCH`,
    defaultMessage: 'Lunch',
  },
  labelDINNER: {
    id: `${scope}.labelDINNER`,
    defaultMessage: 'Dinner',
  },
  labelSNACK: {
    id: `${scope}.labelSNACK`,
    defaultMessage: 'Snack',
  },
  labelDate: {
    id: `${scope}.labelDate`,
    defaultMessage: 'Date',
  },
  labelShare: {
    id: `${scope}.labelShare`,
    defaultMessage: 'Share with Hans Kai group',
  },
  actionCreate: {
    id: `${scope}.actionCreate`,
    defaultMessage: 'Add Meal',
  },
  actionCancel: {
    id: `${scope}.actionCancel`,
    defaultMessage: 'Cancel',
  },
});
