import React from 'react';

// Material UI
import FormControlLabel from '@material-ui/core/FormControlLabel';
import GroupIcon from '@material-ui/icons/People';
import withStyles from '@material-ui/core/styles/withStyles';
import Switch from '@material-ui/core/Switch';

// Data
import { buildSwitchFilterType, getFeedFilter } from '../../../lib/services/app.service';
import { FILTERS } from '../../../lib/constants';

// Components
const IconSwitch = withStyles({
  root: {
    position: 'relative',
    top: '-2px'
  }
})(Switch);

const FilterSwitch = () => {
  const filter = getFeedFilter();
  const switchFilterType = buildSwitchFilterType();

  return (
    <FormControlLabel
      value="start"
      style={{ marginRight: '-8px' }}
      control={<IconSwitch color="default" onChange={switchFilterType} defaultChecked={filter === FILTERS.GROUP} size="small" />}
      label={<GroupIcon />}
      labelPlacement="start"
    />
  );
};

export default FilterSwitch;
