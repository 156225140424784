import _ from 'lodash';
import { useFormik } from 'formik';
import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { object, string, number, boolean } from 'yup';

// Data
import {
  GET_ACTIVITY,
  ADD_ACTIVITY,
  handleActivityCreation,
  handleActivityUpdate, UPDATE_ACTIVITY
} from '../../../lib/queries/activity.queries';
import { ACTIVITY_TYPES, DATE_FORMAT, FILTERS, TIME_OF_DAY } from '../../../lib/constants';

// Components
import CreateActivityForm from '../../../components/activity/CreateActivityForm/create-activity-form.component';
import {
  CreateActivityFormValues
} from '../../../components/activity/CreateActivityForm/create-activity-form.interfaces';
import { getFeedFilter } from '../../../lib/services/app.service';
import { activeWeek } from '../../../lib/queries/app.queries';
import { getAuthUser } from '../../../lib/services/auth.service';
import { refetchData } from '../../../lib/services/activity.service';

const INITIAL_FORM_VALUES = {
  type: ACTIVITY_TYPES.WALKING,
  duration: 30,
  date: dayjs().format('YYYY-MM-DD'),
  timeOfDay: TIME_OF_DAY.MORNING,
  isPublic: true,
};

interface CreateActivityContainerProps {
  onCancel: ClickHandler;
  modelId?: string;
  isShown?: boolean;
}

const CreateActivityContainer: React.FC<CreateActivityContainerProps> = ({ isShown, onCancel, modelId }) => {
  const user = getAuthUser();

  // Get feed filter...
  const filter = getFeedFilter();

  const [addActivity] = useMutation(ADD_ACTIVITY);
  const [updateActivity] = useMutation(UPDATE_ACTIVITY);
  const { data: activityResult } = useQuery(GET_ACTIVITY, {
    variables: { _id: modelId },
    skip: !modelId
  });
  const activity = _.get(activityResult, 'activity');

  function handleSubmit(values: CreateActivityFormValues, { setSubmitting, setValues }: any) {
    const activityWeek = dayjs(values.date, DATE_FORMAT).startOf('week').format(DATE_FORMAT);

    onCancel();

    if (user) {
      // @ts-ignore
      const { _id, name, email } = user;

      const mutationParams: any = {
        mutationVariables: {activityData: _.omit(values, 'messages')},
        mutationResponse: {
          ...values,
          user: {__typename: 'User', _id, name, email },
        },
      };

      if (activity) {
        mutationParams.mutation = updateActivity;
        mutationParams.mutationVariables._id = activity._id;
        mutationParams.mutationResponse._id = activity._id;
        handleActivityUpdate(mutationParams);
      } else {
        mutationParams.cacheQueryVariables = filter === FILTERS.GROUP ? {groupId: _.get(user, 'state.activeGroup') } : {};
        mutationParams.cacheQueryVariables.startDate = activityWeek;
        mutationParams.mutation = addActivity;
        mutationParams.prepend = true;
        handleActivityCreation(mutationParams);
      }
    }

    refetchData(true);
    activeWeek(activityWeek);
    setSubmitting(false);
    setValues(INITIAL_FORM_VALUES);
  }

  const validationSchema = object().shape({
    type: string().required(),
    duration: number().integer('Enter duration in minutes').positive('Enter duration in minutes').required('How long did you do this activity for?'),
    date: string().required().test('date-format', 'Date should be formatted YYYY-MM-DD', function(value) {
      return value.match(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/g);
    }),
    timeOfDay: string().required(),
    isPublic: boolean().required(),
  });

  const formik = useFormik({
    initialValues: { ...INITIAL_FORM_VALUES, ..._.omit(activity, ['user', '_id', '__typename']) },
    onSubmit: handleSubmit,
    validationSchema,
    enableReinitialize: true
  });

  return <CreateActivityForm {...{ isShown, formik, onCancel, isEditing: Boolean(activity) }} />;
};

export default CreateActivityContainer;
