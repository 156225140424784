import styled from 'styled-components';
import FormControl from '@material-ui/core/FormControl';
import withStyles from '@material-ui/core/styles/withStyles';
import Checkbox from '@material-ui/core/Checkbox';
import { RadioButtonChecked, RadioButtonUnchecked } from '@material-ui/icons';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { DatePicker } from '@material-ui/pickers';

interface NwInputProps {
  postfix?: boolean;
}

export const NwFormInput = styled.input<NwInputProps>`
  background: #fff;
  border-radius: ${props => props.postfix ? '2px 0 0 2px' : '2px'};
  border: 1px solid #ddd;
  flex-grow: 1;
  padding: 8px;
  outline: none;
  color: rgba(0, 0, 0, 0.56);
  font-size: 14px;
`;

export const NwFormLabel = styled.label`
  color: rgba(0, 0, 0, 0.54);
  text-transform: uppercase;
  font-size: 14px;
  margin-bottom: 8px;
`;

export const NwFormControl: any = withStyles({
  root: {
    marginBottom: '16px',
  }
})(FormControl);

export const NwRadioInactive = withStyles({
  root: {
  }
})(RadioButtonUnchecked);

export const NwRadioActive = withStyles({
  root: {
    color: '#64CBBA !important',
    '& svg': {
      height: '40px',
      width: '40px'
    }
  }
})(RadioButtonChecked);

export const NwCheckbox = withStyles({
  root: {
    '&.Mui-checked': {
      color: '#64CBBA !important',
    },
    '& .MuiSvgIcon-root': {
      fontSize: '32px',
    },
  },
})(Checkbox);

export const NwRowRadioLabel = withStyles({
  root: {
    margin: 0,
    width: '25%',
    '& .MuiFormControlLabel-label': {
      fontSize: '12px',
      textTransform: 'uppercase',
      color: 'rgba(0, 0, 0, .56)'
    }
  }
})(FormControlLabel);

export const NwTextarea = styled.textarea`
   background: #fff;
   border: 1px solid #ddd;
   border-radius: 4px;
`;

export const NwDatePicker = withStyles({
  root: {
    margin: '18px 0 16px 0',
    background: '#fff',
    border: '1px solid #ddd',
    borderRadius: '2px',
    marginTop: '18px',
    '& #date-picker-dialog-label': {
      textTransform: 'uppercase',
      fontSize: '14px',
      position: 'absolute',
      top: '-18px',
      transform: 'none'
    },
    '& label + .MuiInput-formControl': {
      marginTop: 0,
    },
    '& .MuiInput-underline:before, .MuiInput-underline:after': {
      display: 'none'
    },
    '& input': {
      padding: '9px 8px',
    }
  },
})(DatePicker);
