import React from 'react';
import { withRouter } from 'react-router-dom';
import qs from 'qs';

// Components
import NoDesktop from '../../NoDesktop/no-desktop.component';
import { PageWrapper } from '../../../_core/_ui/structure.components';
import LoginFormContainer from '../../../user/LoginForm/login-form.container';
import SingleFormLayout from '../../_layout/SingleFormLayout/single-form.layout';

interface LoginPageProps {
  location: any;
}

const LoginPage: React.FC<LoginPageProps> = ({ location }) => {
  const { token } = qs.parse(location.search, { ignoreQueryPrefix: true });

  return (
    <PageWrapper>
      <NoDesktop />
      <SingleFormLayout>
        <LoginFormContainer token={token && String(token)} />
      </SingleFormLayout>
    </PageWrapper>
  );
}

export default withRouter(LoginPage);
