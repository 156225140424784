import { defineMessages } from 'react-intl';

export const scope = 'app.components.CreateGroupForm';

export default defineMessages({
  labelName: {
    id: `${scope}.labelName`,
    defaultMessage: 'Name',
  },
  actionCreate: {
    id: `${scope}.actionCreate`,
    defaultMessage: 'Create Group',
  },
});
