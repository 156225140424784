import { defineMessages } from 'react-intl';

export const scope = 'app.components.BottomMenu';

export default defineMessages({
  buttonAddActivity: {
    id: `${scope}.buttonAddActivity`,
    defaultMessage: 'Add Activity',
  },
  buttonAddMeal: {
    id: `${scope}.buttonAddMeal`,
    defaultMessage: 'Add Meal',
  },
});
