import styled from 'styled-components';

export const ProfileIcon = styled.div`
  border-radius: 40px;
  border: 1px solid #ddd;
  height: 40px;
  width: 40px;
  background-color: #27aae1;
  font-size: 14px;
  text-align: center;
  line-height: 40px;
  color: #fff;
  margin-right: 12px;
`;
