import _ from 'lodash';
import React from 'react';
import { Formik } from 'formik';
import qs from 'qs';
import * as Yup from 'yup';
import { useHistory, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import dayjs from 'dayjs';

// Material UI
import Box from '@material-ui/core/Box';

// Data
import { buildAcceptInvite, getInvite } from '../../lib/services/invite.service';
import logo from '../../assets/logo.png';
import logo2x from '../../assets/logo@2x.png';
import { INVITE_STATUSES } from '../../lib/constants';
import { SignUpFormValues } from '../../components/user/SignUpForm/sign-up-form.interfaces';

// Components
import CardLayout from '../../components/layout/CardLayout/card-layout.component';
import AcceptInviteForm from '../../components/invite/AcceptInviteForm/accept-invite-form.component';
import ColourPrimaryButton from '../../components/misc/ColourPrimaryButton/colour-primary-button.component';
import { buildSignOut, getAuthUser } from '../../lib/services/auth.service';

const Msg = styled.div`
  margin-top: 16px;
  text-align: center;
  color: rgba(0, 0, 0, 0.56);
  line-height: 22px;
`;

const Wrapper = styled.div`
  background: #eee;
  height: 100%;
`;

interface SignUpPageContainerProps {
  location: any;
}

const SignPageContainer: React.FC<SignUpPageContainerProps> = ({ location }) => {
  const onSignOut = buildSignOut();
  const user: IUser | null = getAuthUser();
  const history = useHistory();
  const [submitted, setSubmitted] = React.useState(false);
  const { code }: any = qs.parse(location.search, { ignoreQueryPrefix: true });
  const { invite, loading } = getInvite(code);
  const onAcceptInvite = buildAcceptInvite();

  // Sign up server error
  const initialValues: SignUpFormValues = { };
  if (invite) {
    if (invite.email) {
      initialValues.email = invite.email;
    } else if (invite.phone) {
      initialValues.phone = invite.phone;
    }
  };

  async function handleSubmit(values: SignUpFormValues) {
    delete values.confirmPassword;

    onAcceptInvite({ code, ...values })
      .then(() => setSubmitted(true));
  }

  const validationShape: any = {
  };

  if (invite && invite.isPasswordRequired) {
    initialValues.name = '';
    validationShape.name = Yup.string().required('Enter your name');

    initialValues.password = '';
    validationShape.password = Yup.string()
      .required('Enter a password')
      .min(8, 'Password must be at least 8 characters')
      .matches(/^[\S]+$/, 'Password cannot contain any spaces');

    initialValues.confirmPassword = '';
    validationShape.confirmPassword = Yup.string().required().oneOf([Yup.ref('password')], 'Passwords must match');
  }

  if (invite && user && invite.email !== _.get(user, 'email')) {
    onSignOut();
  }

  const validationSchema = Yup.object().shape(validationShape);
  let content;

  if (submitted && invite) {
    content = (
      <Box display="flex" flexDirection="column" alignItems="center">
        <Msg key="submitted">Successfully registered with {invite.groupName}. Sign in</Msg>
        <Box key="sign_in" mt={2}>
          <ColourPrimaryButton variant="contained" onClick={() => history.push('/')}>Sign In</ColourPrimaryButton>
        </Box>
      </Box>
    );
  } else if (loading) {
    content = <Msg key="loading">Loading...</Msg>;
  } else if (invite && invite.status === INVITE_STATUSES.ACCEPTED) {
    content = (
      <Box display="flex" flexDirection="column" alignItems="center">
        <Msg key="accepted">Invite has already been accepted</Msg>
        <Box key="sign_in" mt={2}>
          <ColourPrimaryButton variant="contained" onClick={() => history.push('/')}>Sign In</ColourPrimaryButton>
        </Box>
      </Box>
    );
  } else if (!loading && (!invite || dayjs() > dayjs(invite.expiresAt))) {
    content = <Msg key="expired">Unable to find a valid invitation<br />Please request another invite from a NorWest administrator</Msg>;
  } else {
    content = (
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {form => <AcceptInviteForm form={form} invite={invite}/>}
      </Formik>
    );
  }

  return (
    <Wrapper>
      <CardLayout maxWidth="sm">
        <Box mb={4} style={{ textAlign: 'center' }}>
          <img srcSet={`${logo} 1x, ${logo2x} 2x`} alt="NorWest Coop" />
        </Box>
        <Box display="flex" flexDirection="column">
          {content}
        </Box>
      </CardLayout>
    </Wrapper>
  );
};

export default withRouter(SignPageContainer);
