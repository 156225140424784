import _ from 'lodash';
import React from 'react';

// Material UI
import FormControl from '@material-ui/core/FormControl/FormControl';

// Components
import IntlMsg from '../../_core/IntlMsg/intl-msg.component';
import { NwFormInput, NwFormLabel } from '../../misc/Form/form.components';
import { FormHelperText } from '../../_core/_ui/forms.component';

interface FormikInputProps {
  id?: string,
  fid?: string,
  field: any,
  htmlFor?: string,
  label?: any,
  form: any,
  uncontrolled?: boolean;
}

const FormikInput: React.FC<FormikInputProps> = ({ field, fid, id, label, form, uncontrolled, ...rest }) => {
  const _id = id || fid;
  const error = form.errors[field.name];
  const touched = form.touched[field.name];

  let labelOutput = label;
  if (label && label.id) {
    labelOutput = <NwFormLabel htmlFor={_id}><IntlMsg {...label} /></NwFormLabel>;
  } else if (label && _.isString(label)) {
    labelOutput = <NwFormLabel htmlFor={_id}>{label}</NwFormLabel>;
  }

  const patch: any = {};
  if (!uncontrolled) {
    patch.value = field.value;
  }

  return (
    <FormControl margin="normal" fullWidth component="div" error={!!error && touched}>
      {labelOutput}
      <NwFormInput
        id={_id}
        {...rest}
        name={field.name}
        onChange={field.onChange}
        onBlur={field.onBlur}
        {...patch}
      />
      {touched && _.isString(error) && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  );
}

export default FormikInput;
