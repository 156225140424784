import React, { ReactNode } from 'react';
import dayjs from 'dayjs';
import _ from 'lodash';
import styled from 'styled-components';

// Material UI
import AddIcon from '@material-ui/icons/Add';
import Box from '@material-ui/core/Box';

// Components
import ActivityListItem from '../ActivityListItem/activity-list-item.component';
import ColourPrimaryButton from '../../misc/ColourPrimaryButton/colour-primary-button.component';
import ErrorNotification from '../../misc/Notifications/error-notification.component';
import { createVisibility } from '../../../lib/queries/app.queries';

const Wrapper = styled.div`
  padding: 0 16px 16px;
  flex-grow: 1;
`;

const Placeholder = styled.div`
    width: 100%;
    text-align: center;
    padding: 40px 24px;
    background: rgba(255, 255, 255, 0.56);
    color: rgba(0, 0, 0, 0.56);
    border-radius: 2px;
    margin-top: 16px;
    height: 100%;
    text-transform: uppercase;
`;

const DateHeader = styled.div`
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
  margin-bottom: 4px;
  margin-top: 16px;
`;

const NoticeWrapper = styled.div`
  border-radius: 4px;
  overflow: hidden;
`;

interface ActivityListProps {
  loading: boolean;
  error?: Error;
  activities: Array<IActivity>;
  user?: IUser | null;
}

const ActivityList: React.FC<ActivityListProps> = ({ loading, error, activities, user }) => {
  const onOpenCreateActivity = () => {
    createVisibility('activities');
  };

  const activitiesByDate = _.groupBy(activities, activity => activity.date);
  let listItems = _.reduce(activitiesByDate, (result: Array<ReactNode>, activities: Array<IActivity>, date: string) => {
    result.push(<DateHeader key={date}>{dayjs(date).format('dddd, MMM. D')}</DateHeader>);

    _.each(activities, activity => {
      result.push(<ActivityListItem key={activity._id} user={user} activity={activity} />);
    });

    return result;
  }, []);

  let notice = <></>;
  if (loading && listItems.length === 0) {
    listItems = [(
      <Placeholder key="loading">
        <p>Loading previously created activities</p>
        <p>One moment please</p>
      </Placeholder>
    )];
  } else if (error) {
    notice = <ErrorNotification>Unable to reach Hans Kai servers<br/>Changes will be synced when you reconnect</ErrorNotification>;

    if (listItems.length === 0) {
      listItems = [(
        <Placeholder key="error">
          <Box mb={2}>Previously created activities will be available when you reconnect</Box>
          <ColourPrimaryButton variant="contained" startIcon={<AddIcon />} onClick={onOpenCreateActivity}>Add Activity</ColourPrimaryButton>
        </Placeholder>
      )];
    }
  } else if (listItems.length === 0) {
    listItems = [(
      <Placeholder key="none">
        <Box mb={2}>No activities this week</Box>
        <ColourPrimaryButton variant="contained" startIcon={<AddIcon />} onClick={onOpenCreateActivity}>Add Activity</ColourPrimaryButton>
      </Placeholder>
    )];
  }

  return (
    <Wrapper>
      <NoticeWrapper>{notice}</NoticeWrapper>
      {listItems}
    </Wrapper>
  );
};

export default ActivityList;
