import _ from 'lodash';
import React, { useRef, useCallback, useLayoutEffect } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import useStayScrolled from 'react-stay-scrolled';

import IconButton from '@material-ui/core/IconButton';
import SendIcon from '@material-ui/icons/Send';
import ErrorNotification from '../../misc/Notifications/error-notification.component';
import Message from '../message.component';

// Components
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: calc(100% - 59px);
`;

interface MessageListProps {
  ref: any;
  isPublic: boolean;
}

const MessageList = styled.div<MessageListProps>`
  background: #eee;
  padding: 0 16px 8px;
  flex-grow: 1;
  overflow: scroll;
  margin-top: ${props => props.isPublic ? '0' : '82px'};
`;

const DateHeader = styled.div`
  display: inline-block;
  text-align: center;
  font-size: 12px;
  margin-bottom: 8px;
  margin-top: 16px;
  width: 100%;
`;

interface MessageProps {
  isLocal: boolean;
  isContinuation?: boolean;
  key?: string;
}

const Footer = styled.div`
  border-top: 1px solid #ddd;
  display: flex;
  padding: 8px;
  background: #fff;
`;

interface SendInputProps {
  ref?: any;
}

const SendInput = styled.textarea<SendInputProps>`
  background: #fff;
  border-radius: 4px;
  border: 1px solid #ddd;
  flex-grow: 1;
  padding: 8px;
  margin-right: 8px;
  outline: none;
`;

const ProfileName = styled.div`
  font-weight: bold;
  color: #27aae1;
  font-size: 14px;
  margin-bottom: 4px;
`;

const MetaData = styled.span<MessageProps>`
  display: inline-block;
  float: right;
  padding: ${props => props.isLocal ? '0 0 0 8px' : '0 0 0 16px'};
  position: relative;
  bottom: -6px;
`;


const MetaTime = styled.span<MessageProps>`
  color: ${props => props.isLocal ? 'rgba(255, 255, 255, .65)' : 'rgba(0, 0, 0, .45)'};
  font-size: 11px;
  display: inline-block;
`;

interface MsgNotifProps {
  pinned?: boolean;
}

const MsgNotification = styled.div<MsgNotifProps>`
  background: #27aae1;
  color: #fff;
  padding: 12px;
  border-radius: 4px;
  position: fixed;
  maxWidth: 1280px;
  top: calc(env(safe-area-inset-top) + ${props => props.pinned ? '63px' : '73px'});
  width: calc(100% - 32px);
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  font-size: 14px;
`;

interface ConversationProps {
  localUser?: IUser | null;
  hidden?: boolean;
  messages: IMessage[];
  onSendMessage: Function;
  onMarkConversationRead: Function;
  loading: boolean;
  error: any;
  isPublic: boolean;
  type?: string;
}

const Conversation: React.FC<ConversationProps> = ({ isPublic, type, localUser, hidden, messages, onSendMessage, onMarkConversationRead, error, loading }) => {
  const initialState: any = { content: '', showMsgNotification: false };
  const [state, setState] = React.useState(initialState);
  const listRef = useRef();
  const inputRef = useRef();
  const { stayScrolled, isScrolled, scrollBottom } = useStayScrolled(listRef, { initialScroll: Infinity });

  const onScroll = useCallback(() => {
    if (isScrolled()) setState({ ...state, showMsgNotification: false });
  }, [isScrolled, state]);

  useLayoutEffect(() => {
    setState({ ...state, showMsgNotification: !stayScrolled() });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages.length]);

  function handleSend() {
    if (inputRef && inputRef.current) {
      // @ts-ignore
      inputRef.current.value = '';
    }

    onSendMessage(state.content)
  }

  let lastDate = '20000101';

  let content = [];
  let notice = <></>;

  if (isPublic) {
    if (loading && messages.length === 0) {
      content.push(<MsgNotification key="loading">Loading messages...</MsgNotification>);
    } else if (error && isPublic) {
      notice = <ErrorNotification>Unable to reach Hans Kai servers<br/>Messages will be synced when you
        reconnect</ErrorNotification>;
    }
  }

  if (messages.length > 0) {
    const orderedMessages = [...messages].reverse();

    content = _.map(orderedMessages, (message, index) => {
      const isLocal = message.user._id === _.get(localUser, '_id');
      const stateOnSet = { ...state, [`isOpen${index}`]: !state[`isOpen${index}`] };
      const date = dayjs(Number(message.sentAt));
      const showDateHeader = date.format('YYYYMMDD') > lastDate;
      const isContinuation = !showDateHeader && index !== 0 && orderedMessages[index - 1].user._id === message.user._id;
      const content = [];

      if (showDateHeader) {
        content.push(<DateHeader key={date.format('MMDD')}>{date.format('dddd, MMM D')}</DateHeader>);
        lastDate = date.format('YYYYMMDD');
      }

      return (
        <Message
          markConversationRead={index === orderedMessages.length - 1 ? onMarkConversationRead : null}
          key={message.sentAt}
          isLocal={isLocal}
          isContinuation={isContinuation}
          onClick={() => setState(stateOnSet)}
        >
          {!isLocal && !isContinuation && <ProfileName>{message.user.name}</ProfileName>}
          {message.content}
          <MetaData isLocal={isLocal}><MetaTime isLocal={isLocal}>{date.format('h:mm A')}</MetaTime></MetaData>
        </Message>
      );
    });
  }

  if (!isPublic) {
    content.unshift(<MsgNotification pinned key="not-public">This {type} is private to you<br />Your group will not be able to chat here</MsgNotification>);
  }

  let footer;
  if (error) {
    footer = <p style={{ textAlign: 'center', width: '100%', fontSize: '14px' }}>You can send messages when you reconnect</p>;
  } else {
    footer = (
      <>
        <SendInput ref={inputRef} onChange={e => setState({ ...state, content: e.target.value })} placeholder="Write a comment..." />
        <IconButton onClick={handleSend}>
          <SendIcon />
        </IconButton>
      </>
    )
  }

  return (
    <Wrapper hidden={hidden}>
      {notice}
      <MessageList ref={listRef} onScroll={onScroll} isPublic={isPublic}>
        {content}
        {state.showMsgNotification && <MsgNotification onClick={scrollBottom}>Scroll down to new message</MsgNotification>}
      </MessageList>
      <Footer>
        {footer}
      </Footer>
    </Wrapper>
  );
};

export default Conversation;
