import _ from 'lodash';
import { useMutation, useQuery } from '@apollo/client';

import { ROLES } from '../constants';

import {
  ACCEPT_INVITE,
  GET_INVITE,
  INVITE_FIELDS,
  REMOVE_INVITE,
  RESEND_INVITE,
  SEND_INVITE
} from '../queries/invite.queries';

import { createCacheModifier, removeCacheModifier } from '../cache/basic.cache';

export const getInvite = (code: string) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { error, loading, data } = useQuery(GET_INVITE, {
    skip: !code,
    variables: { code },
    fetchPolicy: 'network-only'
  });

  return { loading, error, invite: _.get(data, 'invite') };
};

export const buildSendInvite = (group: IGroup) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [mutation, rest] = useMutation(SEND_INVITE);

  const sendInvite = ({ email, phone }: { email?: undefined | null | string, phone?: undefined | null | string }) =>
    mutation({
      variables: { data: { email, phone, groupId: group._id, role: ROLES.MEMBER } },
      update: (cache: any, { data }: any) => {
        const createdInvite = _.get(data, 'sendInvite');

        cache.modify({
          id: cache.identify(group),
          fields: {
            invites: createCacheModifier({
              cache,
              createdDoc: createdInvite,
              modelName: 'Invite',
              fragment: INVITE_FIELDS,
              fragmentName: 'InviteFields',
            })
          }
        });
      }
    });

  return { sendInvite, ...rest };
};

export const buildResendInvite = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [resendInvite] = useMutation(RESEND_INVITE);

  return (_id: string) => resendInvite({ variables: { _id } });
};

export const buildCancelInvite = (group: IGroup) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [cancelInvite] = useMutation(REMOVE_INVITE);

  return (_id: string) =>
    cancelInvite({
      variables: { _id },
      update: (cache: any) => {
        cache.modify({
          id: cache.identify(group),
          fields: { invites: removeCacheModifier(cache, _id) }
        });
      }
    });
};

export const buildAcceptInvite = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [acceptInvite] = useMutation(ACCEPT_INVITE);

  return (data: any) => acceptInvite({ variables: { data } });
};
