import React, { useEffect } from 'react';
import styled from 'styled-components';

interface MessageWrapperProps {
  isLocal: boolean;
  isContinuation?: boolean;
}

const Wrapper = styled.div<MessageWrapperProps>`
  clear: both;
  background: ${props => props.isLocal ? '#43C2C1' : '#fff'};
  ${props => props.isContinuation ? 'border-radius: 5px 5px 5px 5px;' : ''}
  ${props => props.isLocal && !props.isContinuation ? 'border-radius: 5px 0 5px 5px;' : ''}
  ${props => !props.isLocal && !props.isContinuation ? 'border-radius: 0 5px 5px 5px;' : ''}
  color: ${props => props.isLocal ? 'rgba(255, 255, 255, 1)' : 'rgba(0, 0, 0, 1)'};
  font-size: 15px;
  line-height: 18px;
  padding: 7px 8px;
  position: relative;
  margin: ${props => props.isContinuation ? '2px 0 0 0' : '8px 0 0 0'};
  max-width: 85%;
  word-wrap: break-word;
  float: ${props => props.isLocal ? 'right' : 'left'};

  &:after {
    display: ${props => props.isContinuation ? 'none' : 'inherit'};
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: ${props => props.isLocal ? '0 0 10px 10px' : '0 10px 10px 0'};
    border-color: ${props => props.isLocal ? 'transparent transparent transparent #43C2C1' : 'transparent #fff transparent transparent'};
    top: 0;
    ${props => props.isLocal ? 'right: -10px' : 'left: -10px'};
  }
`;

interface MessageProps {
  children: any;
  markConversationRead?: Function | null;
  isLocal: boolean;
  isContinuation?: boolean;
  onClick: ClickHandler;
}

const Message: React.FC<MessageProps> = ({ markConversationRead, children, isLocal, isContinuation, onClick }) => {
  useEffect(()=>{
    if (markConversationRead) markConversationRead();
  },[markConversationRead]);

  return <Wrapper onClick={onClick} isLocal={isLocal} isContinuation={isContinuation}>{children}</Wrapper>;
};

export default Message;
