import React from 'react';
import { Field, Form } from 'formik';
import { NavLink } from 'react-router-dom';

// Material UI
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';

// Data
import { LoginFormProps } from './login-form.interfaces';
import messages from './login-form.messages';

// Components
import IntlMsg from '../../_core/IntlMsg/intl-msg.component';
import FormikInput from '../../_forms/Formik/formik-input.component';
import { NwFormLabel } from '../../misc/Form/form.components';
import { Spacer } from '../../_core/_ui/structure.components';
import { PrimaryButton } from '../../_core/_ui/buttons.component';
import { FormHelperText } from '../../_core/_ui/forms.component';

const LoginForm: React.FC<LoginFormProps> = ({ form, submitError }) => {
  return (
    <Form>
      <Field fid="lf" name="username" component={FormikInput} label="Email or Phone" />
      <Field fid="lf" name="password" type="password" component={FormikInput} label={
        <Box display="flex">
          <NwFormLabel>Password</NwFormLabel>
          <Spacer />
          <NavLink tabIndex={-1} to="/forgot-password">Forgot your password?</NavLink>
        </Box>
      } />
      <FormControl margin="normal" fullWidth>
        <PrimaryButton type="submit" variant="contained">
          <IntlMsg {...messages.btnSubmit} />
        </PrimaryButton>
      </FormControl>
      {submitError && <FormHelperText error>{submitError}</FormHelperText>}
    </Form>
  );
};

export default LoginForm;
