import React from 'react';
import styled from 'styled-components';

// Material UI
import Box from '@material-ui/core/Box';
import Check from '@material-ui/icons/CheckCircle';
import IconButton from '@material-ui/core/IconButton';
import HelpIcon from '@material-ui/icons/Help';
import withStyles from '@material-ui/core/styles/withStyles';

// Components
const ListItemHeader = styled.div`
   color: rgba(0, 0, 0, 0.82);
   font-size: 16px;
   font-weight: bold;
   position: relative;
`;

const HelpIconButton = withStyles({
  root: {
    position: 'absolute',
    top: '-13px',
    transform: 'scale(0.9)',
    paddingLeft: '6px'
  }
})(IconButton);

interface ChecklistItemProps {
  header?: string;
  isComplete?: boolean;
  helpUrl?: string;
}

const ChecklistItem: React.FC<ChecklistItemProps> = ({ header, isComplete, helpUrl, children }) => {
  const style = { fontSize: '24px', marginRight: '32px', color: '#DDD', background: '#FAFAFA' };
  if (isComplete) style.color = '#64CBBA';

  return (
    <Box display="flex" my={1}>
      <Check style={style} />
      <Box display="flex" flexDirection="column">
        {header && (
          <ListItemHeader>
            {header}
            {helpUrl && (
              <HelpIconButton onClick={() => window.open(helpUrl, '_system', 'location=yes')}>
                <HelpIcon fontSize="small" />
              </HelpIconButton>
            )}
          </ListItemHeader>
        )}
        {children}
      </Box>
    </Box>
  );
};

export default ChecklistItem;

