import _ from 'lodash';
import { useQuery } from '@apollo/client';

// Queries
import { GET_GROUP, GET_GROUP_SUMMARY } from '../queries/group.queries';

export const getGroup = (_id?: string) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data, ...rest } = useQuery(GET_GROUP, {
    skip: !_id,
    variables: { _id },
    fetchPolicy: 'cache-and-network'
  });

  return { group: _.get(data, 'group'), ...rest };
};

export const getGroupSummary = (_id?: string) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data, ...rest } = useQuery(GET_GROUP_SUMMARY, {
    skip: !_id,
    variables: { _id },
    fetchPolicy: 'cache-and-network'
  });

  return { group: _.get(data, 'group'), ...rest };
}
