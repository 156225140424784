import { gql } from '@apollo/client';

export const USER_STATE_FIELDS = gql`
  fragment UserStateFields on UserState {
    activeGroup
    notificationType
  }
`;

export const RESET_PASSWORD = gql`
  mutation SetPassword($password: String!) {
    resetPassword(password: $password) {
      success
    }
  }
`;

export const REQUEST_PASSWORD_RESET = gql`
  mutation RequestPasswordReset($username: String!) {
    requestPasswordResetLink(username: $username) {
      success
    }
  }
`;

export const RESET_PASSWORD_WITH_TOKEN = gql`
  mutation ResetPasswordWithToken($token: String!, $password: String!) {
    resetPasswordWithToken(token: $token, password: $password) {
      success
    }
  }
`;

export const UPDATE_USER_STATE = gql`
 mutation UpdateUserState($state: String!) {
   updateUserState(state: $state) {
     ...UserStateFields
   }
 }

 ${USER_STATE_FIELDS}
`
