import { defineMessages } from 'react-intl';

export const scope = 'app.components.CheckInForm';

export default defineMessages({
  labelBloodPressure: {
    id: `${scope}.labelBloodPressure`,
    defaultMessage: 'Blood Pressure',
  },
  labelSystolic: {
    id: `${scope}.labelSystolic`,
    defaultMessage: 'Systolic',
  },
  labelDiastolic: {
    id: `${scope}.labelDiastolic`,
    defaultMessage: 'Diastolic',
  },
  labelBloodSugar: {
    id: `${scope}.labelBloodSugar`,
    defaultMessage: 'Blood Sugar',
  },
  labelBloodSugarMeasurement: {
    id: `${scope}.labelBloodSugarMeasurement`,
    defaultMessage: 'mmol/L OR mg/dL'
  },
  labelWaistCircumference: {
    id: `${scope}.labelWaistCircumference`,
    defaultMessage: 'Waist Circumference',
  },
  labelWaistCircumferenceMeasurement: {
    id: `${scope}.labelWaistCircumferenceMeasurement`,
    defaultMessage: 'cm',
  },
  labelHaveEaten: {
    id: `${scope}.labelHaveEaten`,
    defaultMessage: 'Have you eaten in the last 2 hours?'
  }
});
