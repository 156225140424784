import { useApolloClient, useMutation, makeVar, useReactiveVar } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import { LOGOUT } from '../queries/auth.queries';
import SessionReporter from '../utils/session.reporter';

export const authUser = makeVar<IUser | null>(null);

export const getAuthUser = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useReactiveVar(authUser);
}

export const buildSetAuthUser = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const client = useApolloClient();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const history = useHistory();

  return (user: any, redirect?: string) =>
    client.clearStore()
      .then(() => {
        authUser(user);
      })
      .then(() => {
        SessionReporter.identify({
          _id: user._id,
          name: user.name,
          email: user.email
        });

        if (redirect) {
          history.push(redirect);
        }
      });
};

export const buildSignOut = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const history = useHistory();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const client = useApolloClient();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [logout] = useMutation(LOGOUT);

  return (redirect?: boolean) => logout()
    .then(() => {
      authUser(null);
      SessionReporter.logout();
    })
    .then(() => client.clearStore())
    .then(() => {
      localStorage.setItem('logout', String(Date.now()));
      if (redirect) history.push('/');
    });
};
