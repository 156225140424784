import { withStyles } from '@material-ui/core/styles';
import { FormHelperText as MuiFormHelperText } from '@material-ui/core';
import { dangerColor, successColor } from '../../../lib/theme';

export const FormHelperText = withStyles({
  root: {
    fontFamily: 'Quicksand, sans-serif;',
    fontWeight: 700,
    '& .success': {
      color: `${successColor} !important`
    }
  },
  error: {
    color: `${dangerColor} !important`,
  }
})(MuiFormHelperText);

