interface CreateCacheModifierProps {
  cache: any;
  createdDoc: any;
  fragment: any,
  fragmentName: string;
  modelName: string;
  append?: boolean;
}

export const createCacheModifier = ({ cache, createdDoc, fragment, modelName, fragmentName, append = false }: CreateCacheModifierProps) =>
  (existingRefs = [], { readField }: any) => {
    const createdRef = cache.writeFragment({
      id: `${modelName}:${createdDoc._id}`,
      data: createdDoc,
      fragment,
      fragmentName
    });

    if (existingRefs.some(ref => readField('_id', ref) === createdDoc._id)) {
      return existingRefs
    }

    return append
      ? [...existingRefs, createdRef]
      : [createdRef, ...existingRefs];
  };

export const removeCacheModifier = (cache: any, _id: string) =>
  (existingRefs = [], { readField }: any) => {
    return existingRefs.filter(ref => _id !== readField('_id', ref));
  };
