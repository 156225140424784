import _ from 'lodash';
import { ApolloClient, split } from '@apollo/client';
import { createHttpLink } from '@apollo/client/link/http';
import { setContext } from '@apollo/client/link/context';
import { InMemoryCache } from '@apollo/client/cache';
import { WebSocketLink } from '@apollo/client/link/ws';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { persistCache } from 'apollo3-cache-persist';

import { GRAPHQL_HTTP_URI, GRAPHQL_WS_URI } from './constants';
import { authUser } from './services/auth.service';
import AuthApiService from './utils/auth-api.service';

let currToken: string | undefined | null;

setInterval(() => {
  AuthApiService.refreshToken()
    .then(result => {
      authUser(_.get(result, 'user'));
    })
}, 15 * 60 * 1000);

async function createApolloClient(currUser: any) {
  if (currUser) {
    authUser(currUser);
  }

  // HTTP connection
  const httpLink = createHttpLink({
    uri: GRAPHQL_HTTP_URI,
  });

  // Websocket connection
  const wsClient = new SubscriptionClient(GRAPHQL_WS_URI, {
    reconnect: true,
    connectionParams: () => ({
      authToken: _.get(authUser(), 'token'),
    }),
  });
  const wsLink = new WebSocketLink(wsClient);

  // Authentication
  const authLink = setContext((ctx, { headers }) => {
    const nextToken = _.get(authUser(), 'token');

    if (currToken !== nextToken) {
      // wsClient.close(true);
      currToken = nextToken;
    }

    const authorization = currToken ? `Bearer ${currToken}` : null;

    return { headers: { authorization } };
  });

  const currStorage: any = localStorage;
  const cache = new InMemoryCache();
  await persistCache({ cache, storage: currStorage });

  const link = split(
    // split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    authLink.concat(httpLink),
  );

  const client = new ApolloClient({ cache, link, resolvers: {} });

  if (!currUser) {
    await client.clearStore();

    if (window.location.pathname !== '/') {
      localStorage.setItem('logout', String(Date.now()));
    }
  }

  return client;
};

export default createApolloClient;
