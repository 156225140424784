import React, { ReactNode } from 'react';

// Material UI
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

// Data
import logo from '../../../../assets/logo.png';
import logo2x from '../../../../assets/logo@2x.png';
import { APP_TITLE } from '../../../../../_configuration';

// Components
import { SingleFormWrapper } from '../../../_core/_ui/structure.components';
import { Logo } from '../../../_core/_ui/general.components';

interface ISingleFormLayout {
  children: ReactNode;
  width?: string;
}

const SingleFormLayout: React.FC<ISingleFormLayout> = ({ children, width = 'auto' }) => {
  return (
    <React.Fragment>
      <Container>
        <Grid container justify="center">
          <Grid item>
            <Box p={2} mt={6}>
              <Logo srcSet={`${logo} 1x, ${logo2x} 2x`} alt={APP_TITLE} />
            </Box>
          </Grid>
        </Grid>
        <Grid container justify="center">
          <Grid item>
            <SingleFormWrapper width={width}>
              {children}
            </SingleFormWrapper>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default SingleFormLayout;
