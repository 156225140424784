import React from 'react';
import styled from 'styled-components';
import { Field, Form, FormikProps } from 'formik';

// Material UI
import FormControl from '@material-ui/core/FormControl';

// Components
import ColourPrimaryButton from '../../misc/ColourPrimaryButton/colour-primary-button.component';
import FormikInput from '../../_forms/Formik/formik-input.component';

// Content
import { SignUpFormValues } from '../../user/SignUpForm/sign-up-form.interfaces';
import { NwFormLabel } from '../../misc/Form/form.components';

const CompanyListing = styled.div`
  background: #fff;
  border-radius: 4px;
  border: 1px solid #ddd;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & div {
    margin-right: 16px;
    line-height: 22px;
  }
`

interface AcceptInviteFormProps {
  form: FormikProps<SignUpFormValues>;
  invite: IInvite;
}

const AcceptInviteForm: React.FC<AcceptInviteFormProps> = ({ form, invite }) => {
  const { isSubmitting, isValid, values } = form;

  return (
    <Form>
      <FormControl margin="dense" fullWidth component="div">
        <NwFormLabel htmlFor="company_registration__company_name">Hans Kai Group</NwFormLabel>
        <CompanyListing>
          <div>You&#8217;re joining <strong>{invite.groupName}</strong></div>
        </CompanyListing>
      </FormControl>

      {values.email && <Field fid="aif" disabled name="email" type="email" component={FormikInput} label="Email" />}
      {values.phone && <Field fid="aif" disabled name="phone" component={FormikInput} label="Phone" />}

      {invite && invite.isPasswordRequired && (
        <>
          <Field fid="aif" name="name" component={FormikInput} label="Name" />
          <Field fid="aif" name="password" type="password" component={FormikInput} label="Password" />
          <Field fid="aif" name="confirmPassword" type="password" component={FormikInput} label="Confirm Password" />
        </>
      )}

      <FormControl margin="dense">
        <ColourPrimaryButton disabled={isSubmitting || !isValid} type="submit" variant="contained" style={{ maxWidth: '200px' }}>
          {isSubmitting ? "Submitting" : "Accept Invite"}
        </ColourPrimaryButton>
      </FormControl>
    </Form>
  );
};

export default AcceptInviteForm;
