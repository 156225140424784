import _ from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useMutation } from '@apollo/client';
import styled from 'styled-components';

// Material Ui
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import MessageIcon from '@material-ui/icons/Forum';
import BackIcon from '@material-ui/icons/ChevronLeft';

// Data
import { REMOVE_MEAL } from '../../../lib/queries/meal.queries';
import { ENTITIES, FILTERS } from '../../../lib/constants';
import { handleMealDeletion } from '../../../lib/queries/meal.queries';
import { getActiveWeek, getFeedFilter } from '../../../lib/services/app.service';
import { createVisibility, editModel } from '../../../lib/queries/app.queries';
import { buildMarkConversationRead, subscribeToMessages } from '../../../lib/services/message.service';

// Components
import { ListItem } from '../../misc/ListItem/list-item.component';
import BackWrapper from '../../conversation/back-wrapper.component';
import ActionsWrapper from '../../conversation/actions-wrapper.component';
import HeaderContent from '../../conversation/header-content.component';
import { PrimaryContent, SecondaryContent } from '../../conversation/content.component';
import { ProfileIcon } from '../../conversation/profile-icon.component';
import { IconCount, IconSets } from '../../conversation/icon-sets.component';
import ConversationContainer from '../../../containers/conversation/ConversationContainer/conversation.container';

// Content
import messages from '../CreateMealForm/create-meal-form.messages';

interface MealListItemProps {
  meal: IMeal;
  user?: IUser | null;
}

interface ListItemProps {
  isOpen?: boolean;
}

const HeaderWrapper = styled.div<ListItemProps>`
  display: flex;
  flex-direction: row;
  position: relative;
  padding: ${props => props.isOpen ? '12px' : '16px'};
  width: 100%;
`;

const MealListItem: React.FC<MealListItemProps> = ({ meal, user }) => {
  subscribeToMessages({ entity: ENTITIES.MEAL, parentId: meal._id });

  const [{ isOpen }, setState] = React.useState({ isOpen: false });
  const [removeMeal] = useMutation(REMOVE_MEAL);

  // @ts-ignore
  const type = messages[`label${meal.type}`];

  // Get feed filter...
  const filter = getFeedFilter();

  const startDate = getActiveWeek();

  function initiateDelete() {
    if (window.confirm('Are you sure you want to remove this meal?')) {
      const cacheQueryVariables: any = filter === FILTERS.GROUP ? {groupId: _.get(user, 'state.activeGroup') } : {};
      cacheQueryVariables.startDate = startDate;

      handleMealDeletion({
        mutation: removeMeal,
        mutationVariables: {_id: meal._id},
        mutationResponse: {_id: meal._id},
        cacheQueryVariables,
      });
    }
  }

  function initiateEdit(e: any) {
    e.stopPropagation();

    createVisibility('meals');
    editModel(meal._id);
  }

  function getInitials(name: string) {
    return _.reduce(name.split(' '), (result, curr) => {
      return result + curr.charAt(0).toUpperCase();
    }, '');
  }

  const onMarkConversationRead = buildMarkConversationRead();
  function onOpenConversation() {
    onMarkConversationRead(ENTITIES.MEAL, meal._id);
    setState({ isOpen: !isOpen });
  }

  return (
    <ListItem className={isOpen ? 'active' : ''}>
      <HeaderWrapper isOpen={isOpen} onClick={onOpenConversation}>
        {isOpen && <BackWrapper><BackIcon /></BackWrapper>}
        {!isOpen && <ProfileIcon>{getInitials(meal.user.name)}</ProfileIcon>}
        <HeaderContent isOpen={isOpen}>
          <PrimaryContent>{meal.name}</PrimaryContent>
          <SecondaryContent>{meal.user.name} · <FormattedMessage {...type} /></SecondaryContent>
          <IconSets>
            {!isOpen && meal.totalMsg > 0 && <IconCount unread={meal.unreadMsg > 0}><MessageIcon /><div>{meal.totalMsg}</div></IconCount>}
          </IconSets>
        </HeaderContent>
        {user && meal.user._id === user._id && isOpen && (
          <ActionsWrapper>
            <IconButton onClick={initiateEdit}><EditIcon /></IconButton>
            <IconButton onClick={initiateDelete}><DeleteIcon /></IconButton>
          </ActionsWrapper>
        )}
      </HeaderWrapper>
      {isOpen && <ConversationContainer type="meal" isPublic={meal.isPublic} parentId={meal._id} entity="Meal" />}
    </ListItem>
  );
};

export default MealListItem;
