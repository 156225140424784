import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import theme from '../../../lib/theme';

export const BaseButton = withStyles(theme => ({
  root: {
    boxShadow: 'none',
    fontSize: '16px',
    padding: '8px 16px',
  },
}))(Button);

export const PrimaryButton = withStyles(theme => ({
  root: {
    background: '#64CBBA',
    border: '1px solid #fff',
    color: '#fff',

    '&:hover': {
      background: '#44AB9A'
    }
  }
}))(BaseButton);

export const SecondaryButton = withStyles(theme => ({

}))(BaseButton);

export const OutlineButton = withStyles(theme => ({
  root: {
    borderRadius: '8px',
    border: '2px solid #64CBBA'
  }
}))(BaseButton);

export const ButtonRow = styled.div`
  display: flex;

  & > button {
    margin: 0 ${theme.spacing(1)}px;
  }

  & > button:first-child {
    margin-left: 0;
  }

  & > button:last-child {
    margin-right: 0;
  }
`;
