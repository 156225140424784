import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
// import {
//   ActionPerformed,
//   PushNotificationSchema,
//   PushNotifications,
//   Token,
// } from '@capacitor/push-notifications';

// Data
import { ENTITIES } from '../../../lib/constants';
import { subscribeToMessages } from '../../../lib/services/message.service';
import { getGroupSummary } from '../../../lib/services/group.service';
import { buildSignOut, getAuthUser } from '../../../lib/services/auth.service';

// Components
import AppLayout from '../../layout/AppLayout/app-layout.component';
import NoDesktop from '../NoDesktop/no-desktop.component';

const ScrollWrapper = styled.div`
  height: calc(100% - 112px - env(safe-area-inset-bottom) - env(safe-area-inset-top));
  overflow-y: scroll;
`;

interface AppLayoutProps {
  activeTab: string;
}

const AppLayoutPageContainer: React.FC<AppLayoutProps> = ({ activeTab, children }) => {
  const history = useHistory();
  const onSignOut = buildSignOut();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // useEffect(() => {
  //   // Request permission to use push notifications
  //   // iOS will prompt user and return if they granted permission or not
  //   // Android will just grant without prompting
  //   PushNotifications.requestPermissions().then((result: any) => {
  //     if (result.receive === 'granted') {
  //       // Register with Apple / Google to receive push via APNS/FCM
  //       PushNotifications.register();
  //     }
  //   });
  //
  //   // On success, we should be able to receive notifications
  //   PushNotifications.addListener('registration',
  //     (token: Token) => {
  //       console.log('Push registration success, token: ' + token.value);
  //       // TODO: Register push on backend
  //     }
  //   );
  //
  //   // Some issue with our setup and push will not work
  //   PushNotifications.addListener('registrationError',
  //     (error: any) => {
  //       console.error('Error on registration: ' + JSON.stringify(error));
  //     }
  //   );
  //
  //   // Show us the notification payload if the app is open on our device
  //   PushNotifications.addListener('pushNotificationReceived',
  //     (notification: PushNotificationSchema) => {
  //       console.log('Push received: ' + JSON.stringify(notification));
  //       // TODO: Update notification count
  //     }
  //   );
  //
  //   // Method called when tapping on a notification
  //   PushNotifications.addListener('pushNotificationActionPerformed',
  //     (notification: ActionPerformed) => {
  //       alert('Push action performed: ' + JSON.stringify(notification));
  //       // TODO: Navigate to notification
  //     }
  //   );
  // }, []);

  const user = getAuthUser();
  const { group } = getGroupSummary(_.get(user, 'state.activeGroup'));

  subscribeToMessages({ entity: ENTITIES.GROUP, parentId: _.get(user, 'state.activeGroup') });

  let heading;

  function onChangeTab(nextTab: string) {
    history.push(`/${nextTab}`)
  }

  switch (activeTab) {
    case 'chat':
      heading = 'Hans Kai Group Chat';
      break;
    case 'check-in':
      heading = 'Health Check-In';
      break;
    case 'activities':
      heading = "Physical Activity";
      break;
    case 'meals':
      heading = "Meals";
      break;
    case 'groups':
      heading = "Groups";
      break;
  }

  return (
    <>
      <NoDesktop />
      <AppLayout maxWidth="lg" onSignOut={onSignOut} heading={heading} onChangeTab={onChangeTab} activeTab={activeTab} group={group} user={user} history={history}>
        <ScrollWrapper>
          {children}
        </ScrollWrapper>
      </AppLayout>
    </>
  );
};

export default AppLayoutPageContainer;
