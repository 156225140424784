import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';

export const RadioCard = withStyles({
  root: {
    background: '#fff',
    width: 'calc(33.33% - 8px)',
    margin: '4px',
    padding: '8px',
    float: 'left',
    fontSize: '14px',
  },
})(Button);
