export const APP_TITLE = 'Hans Kai';
export const SUPPORT_EMAIL = 'support@hanskai.ca';

export const ENABLE_DEKSTOP = false;
export const ENABLE_MOBILE = true;

export const ENABLE_SENTRY = true;
export const ENABLE_LOGROCKET = false;
export const ENABLE_CHAT = false;
export const HAS_PHI = true;

export const HAS_USERS = true;
export const OPEN_REGISTRATION = HAS_USERS && false;

export const HAS_COMPANIES = true;
export const COMPANY_TERM = 'group';

export const HAS_PROJECTS = false;
export const PROJECT_TERM = '';

export const ROLES = {
  ADMIN: 'ADMIN',
  OWNER: 'OWNER',
  MEMBER: 'MEMBER',
};

export const ROUTE_ROLES = {
  PUBLIC: 'PUBLIC',
  AUTHORIZED: 'AUTHORIZED',
  ...ROLES
};

export const HOME_ROUTE = '/activities';
export const LOGIN_ROUTE = '/';
