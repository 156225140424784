import styled from 'styled-components';

interface HeaderContentProps {
  isOpen: boolean;
}

const HeaderContent = styled.div<HeaderContentProps>`
  flex-grow: 1;
  margin-left: ${props => props.isOpen ? '42px' : '0'};
  font-size: ${props => props.isOpen ? '14px' : 'inherit'};
`;

export default HeaderContent;
