import { FormikProps } from 'formik';

export interface CheckInFormProps {
  formik: FormikProps<CheckInFormValues>;
}

export interface CheckInFormValues {
  bloodPressureOver?: number,
  bloodPressureUnder?: number,
  bloodSugar?: number,
  waistCircumference?: number,
  hasEaten: boolean
}

export const INITIAL_FORM_VALUES: CheckInFormValues = {
  hasEaten: false
};
