import styled from 'styled-components';
import theme from '../../../lib/theme';

export const Spacer = styled.div`
  flex-grow: 1;
`;

interface ISingleFormWrapper {
  width: string;
}

export const SingleFormWrapper = styled.div<ISingleFormWrapper>`
  box-sizing: content-box;
  padding: ${theme.spacing(2)}px;
  width: ${props => props.width};

  & > *:first-child, > form > *:first-child {
    margin-top: 0;
  }

  & > *:last-child, > form > *:last-child {
    margin-bottom: 0;
  }
`;

export const PageWrapper = styled.div`
  background: #eee;
  height: 100%;
`;
