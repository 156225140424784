import _ from 'lodash';
import React from 'react';
import { useQuery } from '@apollo/client';

// Data
import { GET_MEALS } from '../../lib/queries/meal.queries';
import { FILTERS } from '../../lib/constants';

// Components
import MealList from '../../components/meal/MealList/meal-list.component';
import DateRangeControlContainer from '../../components/misc/DateRangeControl/date-range-control.container';
import { getActiveWeek, getFeedFilter } from '../../lib/services/app.service';
import { getAuthUser } from '../../lib/services/auth.service';

interface GetMealsVariables {
  userId?: string;
  groupId?: string;
  startDate: string;
}

interface GetMealsOptions {
  variables: GetMealsVariables;
  skip: boolean;
  pollInterval?: number;
  fetchPolicy: any;
}

const MealPageContainer: React.FC = () => {
  const [hasLoaded, setHasLoaded] = React.useState(false);

  const user = getAuthUser();

  // Get feed filter...
  const filter = getFeedFilter();
  const startDate = getActiveWeek();

  // Get meals...
  const options: GetMealsOptions = {
    variables: { startDate },
    skip: false,
    pollInterval: 30000,
    fetchPolicy: 'cache-and-network'
  };

  if (user && filter === FILTERS.GROUP) {
    options.variables.groupId = _.get(user, 'state.activeGroup');
  } else if (filter === FILTERS.GROUP) {
    options.skip = true;
  }

  const { loading, error, data } = useQuery(GET_MEALS, options);
  if (!loading && !hasLoaded) setHasLoaded(true);

  const meals = _.get(data, 'meals', []);

  return (
    <>
      <DateRangeControlContainer />
      <MealList { ...{ meals, loading: !hasLoaded, error, filter, user } } />
    </>
  );
};

export default MealPageContainer;
