import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { IonApp } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { ThemeProvider } from '@material-ui/core/styles';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

import './theme/variables.css';
import './theme/global.css';
import theme from './app/lib/theme';

// Components
import PersistedApolloProvider from './app/containers/misc/PersistGateContainer/persist-gate.container';
import RouteByRole from './app/components/misc/RouteByRole/route-by-role.component';
import SignUpPageContainer from './app/pages/SignUpPage/sign-up-page.container';
import ManageGroupsPageContainer from './app/pages/ManageGroupsPage/manage-groups-page.container';
import { IntlProvider } from 'react-intl';
import NotFoundPage from './app/components/_pages/NotFoundPage/not-found-page.component';
import ActivityPageContainer from './app/pages/ActivityPage/activity-page.container';
import MealPageContainer from './app/pages/MealPage/meal-page.container';
import ChatPageContainer from './app/pages/ChatPage/chat-page.container';
import AppLayoutPageContainer from './app/components/_pages/AppLayoutPage/app-layout-page.container';
import CheckInPageContainer from './app/pages/CheckInPage/check-in-page.container';
import ResetPasswordPage from './app/pages/PasswordResetPage/password-reset-page.container';
import ForgotPasswordPage from './app/components/_pages/_auth/ForgotPasswordPage/forgot-password.page';
import LoginPage from './app/components/_pages/_auth/LoginPage/login.page';
import NotificationsPageContainer from './app/pages/NotificationsPage/notifications-page.container';

if (process.env.REACT_APP_SENTRY_ENV && process.env.REACT_APP_SENTRY_ENV !== 'development') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY,
    environment: process.env.REACT_APP_SENTRY_ENV,
    integrations: [
      new Integrations.BrowserTracing()
    ],
    tracesSampleRate: process.env.REACT_APP_SENTRY_ENV === 'production' ? 0.1 : 1.0
  });
}

const App: React.FC = () => (
  <PersistedApolloProvider>
    <ThemeProvider theme={theme}>
      <IntlProvider locale="en">
        <IonApp>
          <IonReactRouter>
            <Switch>
              <RouteByRole exact title="NorWest, Hans Kai - Sign In" path="/" publicPage={LoginPage} />
              <RouteByRole exact title="NorWest, Hans Kai - Forgot Password" path="/forgot-password" publicPage={ForgotPasswordPage} />
              <RouteByRole exact title="NorWest, Hans Kai - Sign In" path="/password-reset" publicPage={ResetPasswordPage} />
              <RouteByRole exact title="NorWest, Hans Kai - Join" path="/join" component={SignUpPageContainer} />
              <RouteByRole exact title="NorWest, Hans Kai - Notifications" path="/notifications" wrapper={AppLayoutPageContainer} component={NotificationsPageContainer} />
              <RouteByRole exact activeTab="chat" path="/chat" wrapper={AppLayoutPageContainer} authPage={ChatPageContainer} />
              <RouteByRole exact activeTab="activities" path="/activities" wrapper={AppLayoutPageContainer} authPage={ActivityPageContainer} />
              <RouteByRole exact activeTab="activities" path="/activities/:activityId" wrapper={AppLayoutPageContainer} authPage={ActivityPageContainer} />
              <RouteByRole exact activeTab="meals" path="/meals" wrapper={AppLayoutPageContainer} authPage={MealPageContainer} />
              <RouteByRole exact activeTab="meals" path="/meals/:mealId" wrapper={AppLayoutPageContainer} authPage={MealPageContainer} />
              <RouteByRole exact activeTab="check-in" path="/check-in" wrapper={AppLayoutPageContainer} authPage={CheckInPageContainer} />
              <RouteByRole exact activeTab="groups" title="Group Management - NorWest, Hans Kai" path="/groups" wrapper={AppLayoutPageContainer} adminPage={ManageGroupsPageContainer} />
              <Route component={NotFoundPage} />
            </Switch>
          </IonReactRouter>
        </IonApp>
      </IntlProvider>
    </ThemeProvider>
  </PersistedApolloProvider>
);

export default App;
