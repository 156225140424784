import React, { ReactNode } from 'react';
import dayjs from 'dayjs';
import _ from 'lodash';
import styled from 'styled-components';

// Material UI
import Box from '@material-ui/core/Box';
import AddIcon from '@material-ui/icons/Add'

// Components
import MealListItem from '../MealListItem/meal-list-item.component';
import ErrorNotification from '../../misc/Notifications/error-notification.component';
import ColourPrimaryButton from '../../misc/ColourPrimaryButton/colour-primary-button.component';
import { createVisibility } from '../../../lib/queries/app.queries';

const Wrapper = styled.div`
  background: #FAFAFA;
  display: flex;
  flex-direction: column;
  min-height: 100%;
`;

const DateHeader = styled.div`
  color: rgba(0, 0, 0, 0.86);
  text-transform: uppercase;
  font-size: 14px;
  margin-top: 16px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 4px;
  padding-left: 15px;
`;

const Placeholder = styled.div`
  width: 100%;
  padding: 40px;
  border-radius: 40px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: rgba(0, 0, 0, 0.56);
;`

interface MealListProps {
  loading: boolean;
  error?: Error;
  meals: Array<IMeal>;
  user?: IUser | null;
}

const MealList: React.FC<MealListProps> = ({ loading, error, meals, user }) => {
  const onOpenCreateMeal = () => {
    createVisibility('meals');
  };

  const mealsByDate = _.groupBy(meals, meal => meal.date);
  let listItems = _.reduce(mealsByDate, (result: Array<ReactNode>, meals: Array<IMeal>, date: string) => {
    result.push(<DateHeader>{dayjs(date).format('dddd, MMM. D')}</DateHeader>);

    _.each(meals, meal => {
      result.push(<MealListItem user={user} meal={meal} />);
    });

    return result;
  }, []);

  let notice = <></>;
  if (loading && listItems.length === 0) {
    listItems = [(
      <Placeholder key="loading">
        <p>Loading previously created meals</p>
        <p>One moment please</p>
      </Placeholder>
    )];
  } else if (error) {
    notice = <ErrorNotification>Unable to reach Hans Kai servers<br/>Changes will be synced when you
      reconnect</ErrorNotification>;

    if (listItems.length === 0) {
      listItems = [(
        <Placeholder key="error">
          <Box mb={2}>Previously created meals will be available when you reconnect</Box>
          <ColourPrimaryButton variant="contained" startIcon={<AddIcon />} onClick={onOpenCreateMeal}>Add Meal</ColourPrimaryButton>
        </Placeholder>
      )];
    }
  } else if (listItems.length === 0) {
    listItems = [(
      <Placeholder key="none">
        <Box mb={2}>No meals this week</Box>
        <ColourPrimaryButton variant="contained" startIcon={<AddIcon />} onClick={onOpenCreateMeal}>Add Meal</ColourPrimaryButton>
      </Placeholder>
    )];
  }

  return (
    <Wrapper>
      {notice}
      {listItems}
    </Wrapper>
  );
};

export default MealList;
