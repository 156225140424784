import styled from 'styled-components';

export const IconSets = styled.div`
  position: absolute;
  right: 16px;
  bottom: 13px;
`;

interface IconCountProps {
  unread?: boolean;
}

export const IconCount = styled.div<IconCountProps>`
  display: flex;
  flex-direction: row;
  color: ${props => props.unread ? 'rgba(0, 0, 0, 0.56)' : 'rgba(0, 0, 0, 0.26)'};
  
  & div {
    margin-left: 8px;
    font-size: 15px;
  }
  
  & svg {
    font-size: 18px;
  }
`;
