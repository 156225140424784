import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

// Data
import { LoginFormContainerProps, LoginFormFields } from './login-form.interfaces';
import AuthApiService from '../../../lib/utils/auth-api.service';
import { buildSetAuthUser } from '../../../lib/services/auth.service';
import { HOME_ROUTE } from '../../../../_configuration';

// Components
import LoginForm from './login-form.component';

const LoginFormContainer: React.FC<LoginFormContainerProps> = ({ token }) => {
  const [submitError, setSubmitError] = useState<string | null>();
  const onSetAuthUser = buildSetAuthUser();

  // Auto sign in if we have a token...
  if (token) {
    AuthApiService.authenticateWithToken({ token })
      .then((nextUser: IUser) => {
        return onSetAuthUser(nextUser, HOME_ROUTE);
      })
  }

  async function handleSubmit(values: LoginFormFields, { setSubmitting }: any) {
    setSubmitError(null);

    try {
      values.username = values.username.toLowerCase();
      const nextUser = await AuthApiService.authenticate(values);

      await onSetAuthUser(nextUser, HOME_ROUTE);
    } catch (err: any) {
      setSubmitError(err.message);
    }

    setSubmitting(false);
  }

  const INITIAL_FORM_VALUES = { username: '', password: '' };


  const validationSchema = Yup.object().shape({
    username: Yup.string().required('Enter the email or phone number you signed up with'),
    password: Yup.string().required('Password is required')
  });

  return (
    <Formik
      initialValues={INITIAL_FORM_VALUES}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {form => <LoginForm form={form} submitError={submitError} />}
    </Formik>
  );
}

export default LoginFormContainer;
