import _ from 'lodash';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { useFormik } from 'formik';
import { object, number } from 'yup';
import { useMutation, useQuery } from '@apollo/client';

// Material UI
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Dialog } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';

// Data
import { handleCheckInCreation, ADD_CHECKIN, GET_CHECKINS } from '../../lib/queries/check-in.queries';
import { buildRemoveCheckIn } from '../../lib/services/check-in.service';

// Components
import CheckInForm from '../../components/check-in/CheckInForm/check-in-form.component';
import { CheckInFormValues, INITIAL_FORM_VALUES } from './check-in-page.interfaces';
import messages from './check-in-page.messages';

const Wrapper = styled.div`
  color: rgba(255, 255, 255, 0.86);
  font-weight: lighter;
  padding: 0 24px;
`;

const Heading = styled.div`
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 4px;
`;

const Table = styled.table`
  border: 1px solid #ddd;
  width: 100%;
  text-align: left;
`;

const TableRow = styled.tr`
  padding: 8px;
  border-bottom: 1px solid #eee;
  &:nth-child(event) {
    background: #f0f0f0;
  }
`;

const TableCell = styled.td`
  padding: 8px;
  &:last-child {
    border-right: none;
  }
`;

// @ts-ignore
const TableHeading = styled(TableCell)`
  font-weight: bold;
`

const CheckInPageContainer: React.FC = () => {
  const [pendingRemoval, setPendingRemoval] = useState<any | null>();

  const onRemoveCheckIn = buildRemoveCheckIn();
  const onConfirmRemoveCheckIn = () => {
    onRemoveCheckIn(pendingRemoval?._id);
    setPendingRemoval(null);
  };

  const { loading, error, data } = useQuery(GET_CHECKINS, { fetchPolicy: 'cache-and-network' });
  const checkIns = _.get(data, 'checkIns', []);

  const [addCheckIn] = useMutation(ADD_CHECKIN);

  async function handleSubmit(values: CheckInFormValues, { setSubmitting, setValues }: any) {
    const mutationParams: any = {
      mutation: addCheckIn,
      mutationVariables: { data: values },
      mutationResponse: { ...values },
    };

    handleCheckInCreation(mutationParams);

    setSubmitting(false);
    setValues({
      bloodPressureOver: '',
      bloodPressureUnder: '',
      bloodSugar: '',
      waistCircumference: '',
      hasEaten: false
    });
  }

  const validationSchema = object().shape({
    bloodPressureOver: number().required('Required'),
    bloodPressureUnder: number().required('Required'),
    bloodSugar: number().required('Required'),
    waistCircumference: number().required('Required'),
  });

  const formik = useFormik({
    initialValues: { ...INITIAL_FORM_VALUES },
    onSubmit: handleSubmit,
    validationSchema
  });

  return (
    <Wrapper>
      <div style={{ color: 'rgba(255, 255, 255, 0.86)', marginBottom: '16px' }}><FormattedMessage {...messages.instructions} /></div>
      <Card>
        <CardContent style={{ position: 'relative', height: '515px' }}>
          <CheckInForm formik={formik} />
        </CardContent>
      </Card>
      {!loading && !error && (
        <Box display="flex" flexDirection="column" my={4} hidden={!checkIns.length}>
          <Heading>History</Heading>
          <Card>
            <Table>
              <thead>
                <TableRow>
                  <TableHeading>Date</TableHeading>
                  <TableHeading>BP</TableHeading>
                  <TableHeading>BS</TableHeading>
                  <TableHeading>WC</TableHeading>
                </TableRow>
              </thead>
              <tbody>
                {_.map(checkIns, checkIn => {
                  const formattedDate = dayjs(Number(checkIn.createdAt)).format('MMM. DD');

                  return (
                    <TableRow key={checkIn._id} onClick={() => setPendingRemoval(checkIn)}>
                      <TableCell>{formattedDate}</TableCell>
                      <TableCell>{checkIn.bloodPressureOver} / {checkIn.bloodPressureUnder}</TableCell>
                      <TableCell>{checkIn.bloodSugar}</TableCell>
                      <TableCell>{checkIn.waistCircumference}</TableCell>
                    </TableRow>
                  );
                })}
              </tbody>
            </Table>
          </Card>
          <Dialog open={Boolean(pendingRemoval)} onClose={() => setPendingRemoval(null)}>
            <DialogContent>
              {pendingRemoval && (
                <DialogContentText>
                  Remove check-in entry on {dayjs(Number(pendingRemoval.createdAt)).format('MMM. DD')}?<br />
                  <strong>BP:</strong> {pendingRemoval.bloodPressureOver} / {pendingRemoval.bloodPressureUnder}<br />
                  <strong>BS:</strong> {pendingRemoval.bloodSugar}<br />
                  <strong>WC:</strong> {pendingRemoval.waistCircumference}
                </DialogContentText>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setPendingRemoval(null)} color="primary">Cancel</Button>
              <Button onClick={onConfirmRemoveCheckIn} color="primary">Remove</Button>
            </DialogActions>
          </Dialog>
        </Box>
      )}
    </Wrapper>
  );
};

export default CheckInPageContainer;
