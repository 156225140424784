import _ from 'lodash';
import React from 'react';
import { FormattedMessage, createIntl, createIntlCache, RawIntlProvider } from 'react-intl';
import dayjs from 'dayjs';
import styled from 'styled-components';

// Material UI
import Box from '@material-ui/core/Box';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Button from '@material-ui/core/Button';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  DatePicker,
} from '@material-ui/pickers';

// Components
import ColourPrimaryButton from '../../misc/ColourPrimaryButton/colour-primary-button.component';
import { RadioCard } from './radio-card.component';
import { NwFormControl, NwFormInput, NwFormLabel, NwRadioActive, NwRadioInactive, NwCheckbox } from '../../misc/Form/form.components';

// Content
import { CreateActivityFormProps } from './create-activity-form.interfaces';
import messages from './create-activity-form.messages';
import { ACTIVITY_ICONS, ACTIVITY_TYPES, DATE_FORMAT, TIME_OF_DAY } from '../../../lib/constants';
import withStyles from '@material-ui/core/styles/withStyles';

const Title = styled.h1`
  color: rgba(0, 0, 0, 0.56);
  text-transform: uppercase;
  margin-top: 0;
`;

const ActivityTypeGroup = withStyles({
  root: {
    display: 'block',
    textAlign: 'center',
    margin: '0 -4px',
  }
})(RadioGroup);

const ActivityTypeLabel = withStyles({
  root: {
    margin: 0
  },
})(FormControlLabel);

const ShareLabel = withStyles({
  root: {
    textTransform: 'uppercase',
    padding: '8px 12px',
    background: '#fff',
    border: '1px solid #ddd',
    borderRadius: '4px',
    margin: '16px 0 0',
    color: 'rgba(0, 0, 0, 0.56)',
    '& .MuiFormControlLabel-label': {
      position: 'relative',
      top: '1px',
      fontSize: '14px'
    }
  },
})(FormControlLabel);

const TimeOfDayControl = withStyles({
  root: {
    margin: 0,
    width: '25%',
    '& .MuiFormControlLabel-label': {
      fontSize: '12px',
      textTransform: 'uppercase',
      color: 'rgba(0, 0, 0, .56)'
    }
  }
})(FormControlLabel);

const NwDatePicker = withStyles({
  root: {
    margin: '18px 0 16px 0',
    background: '#fff',
    border: '1px solid #ddd',
    borderRadius: '2px',
    marginTop: '18px',
    '& #date-picker-dialog-label': {
      textTransform: 'uppercase',
      fontSize: '14px',
      position: 'absolute',
      top: '-18px',
      transform: 'none'
    },
    '& label + .MuiInput-formControl': {
      marginTop: 0,
    },
    '& .MuiInput-underline:before, .MuiInput-underline:after': {
      display: 'none'
    },
    '& input': {
      padding: '9px 8px',
    }
  },
})(DatePicker);

const Postfix = styled.div`
  padding: 8px;
  border-top: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  border-radius: 0 2px 2px 0;
  background: #fefefe;
  color: rgba(0, 0, 0, 0.56);
`;


interface IsShownProps {
  isShown?: boolean;
}

const SubmitBox = styled.div<IsShownProps>`
  display: flex;
  padding: 16px 16px max(16px, calc(env(safe-area-inset-bottom) + 16px));
  position: fixed;
  bottom: ${props => props.isShown ? 0 : '-600px'};
  width: 100%;
  left: 0;
  background: #fff;
  border-top: 1px solid #ddd;
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
`;

interface IconProps {
  active?: boolean;
}

const ActivityIcon = styled.img<IconProps>`
  height: 32px;
  width: 32px;
  ${props => props.active && 'filter: brightness(0) invert(1);'}
`;

const NoIcon = styled.div`
  height: 32px;
  width: 32px;
`;

const activeButtonStyle = { background: '#64CBBA', color: '#fff' };
const inactiveStyle = {};

const CreateActivityForm: React.FC<CreateActivityFormProps> = ({ isShown, isEditing, formik, onCancel }) => {
  const {
    handleSubmit,
    handleBlur,
    handleChange,
    isSubmitting,
    errors,
    touched,
    values,
    setFieldValue
  } = formik;

  const cache = createIntlCache();
  // @ts-ignore
  const intl = createIntl({ locale: 'en', messages }, cache);

  return (
    <form style={{ position: 'relative' }} onSubmit={handleSubmit}>
      <RawIntlProvider value={intl}>
        <Box display="flex" flexDirection="column" height="100%" mb="62px" style={{ paddingTop: 'env(safe-area-inset-top)' }}>
          <Title>{isEditing ? 'Edit Activity' : 'New Activity'}</Title>

          {/* Activity Type */}
          <NwFormControl component="fieldset">
            <NwFormLabel>
              <FormattedMessage {...messages.labelType} />
            </NwFormLabel>
            <ActivityTypeGroup aria-label="type" name="type" value={values.type} onChange={handleChange}>
              {_.map(ACTIVITY_TYPES, type => {
                const isActive = type === values.type;

                // @ts-ignore
                const label = intl.formatMessage(messages[`label${type}`]);
                // @ts-ignore
                const icon = ACTIVITY_ICONS[type] ? <ActivityIcon src={ACTIVITY_ICONS[type]} /> : <NoIcon />;
                // @ts-ignore
                const activeIcon = ACTIVITY_ICONS[type] ? <ActivityIcon active src={ACTIVITY_ICONS[type]} /> : <NoIcon />;

                return (
                  <RadioCard key={type} variant="contained" style={isActive ? activeButtonStyle : inactiveStyle}>
                    <ActivityTypeLabel key={type} labelPlacement="bottom" value={type} control={<Radio icon={icon} checkedIcon={activeIcon} />} label={label}/>
                  </RadioCard>
                );
              })}
            </ActivityTypeGroup>
          </NwFormControl>
          {/*<Divider />*/}

          {/* Duration */}
          <NwFormControl component="div" error={!!errors.duration && touched.duration}>
            <NwFormLabel htmlFor="create_activity__duration">
              <FormattedMessage {...messages.labelDuration} />
            </NwFormLabel>
            <Box display="flex">
              <NwFormInput
                id="create_activity__duration"
                type="number"
                name="duration"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.duration}
                aria-describedby="create_activity__duration__error_text"
                autoComplete="off"
                postfix
              />
              <Postfix>minutes</Postfix>
            </Box>
            {_.isString(errors.duration) && touched.duration && (
              <FormHelperText id="create_activity__duration__error_text">
                {errors.duration}
              </FormHelperText>
            )}
          </NwFormControl>

          {/* Date */}
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <NwDatePicker
              margin="normal"
              id="date-picker-dialog"
              label="Date"
              format="yyyy-MM-dd"
              value={dayjs(values.date).toDate()}
              onChange={date => { setFieldValue('date', dayjs(date || '').format(DATE_FORMAT)); }}
            />
          </MuiPickersUtilsProvider>

          {/* Time of Day */}
          <NwFormControl component="fieldset">
            <RadioGroup aria-label="time" name="timeOfDay" value={values.timeOfDay} onChange={handleChange} row>
              {_.map(TIME_OF_DAY, time => {
                // @ts-ignore
                const label = intl.formatMessage(messages[`label${time}`]);

                return (
                  <TimeOfDayControl
                    key={time}
                    value={time}
                    control={<Radio icon={<NwRadioInactive fontSize="large" />} checkedIcon={<NwRadioActive fontSize="large" />} />}
                    labelPlacement="bottom"
                    label={label}
                  />
                );
              })}
            </RadioGroup>
          </NwFormControl>
          {/*<Divider />*/}
          <NwFormControl component="div">
            <ShareLabel
              control={<NwCheckbox checked={values.isPublic} size="medium" onChange={handleChange} name="isPublic" />}
              label={intl.formatMessage(messages.labelShare)}
            />
          </NwFormControl>
        </Box>
        <SubmitBox isShown={isShown}>
          <Button onClick={onCancel}>
            <FormattedMessage {...messages.actionCancel} />
          </Button>
          <div style={{ flexGrow: 1 }} />
          <ColourPrimaryButton type="submit" disabled={isSubmitting} variant="contained">
            {isEditing ? 'Save Activity' : 'Add Activity'}
          </ColourPrimaryButton>
        </SubmitBox>
      </RawIntlProvider>
    </form>
  );
};

export default CreateActivityForm;
