import { withStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import styled from 'styled-components';

export const ListItemCard = withStyles({
  root: {
    marginBottom: '8px',
    transition: 'all 1s',
    '&.active': {
      position: 'fixed',
      borderRadius: 0,
      display: 'flex',
      flexDirection: 'column',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 10000,
      '& .MuiCardContent-root': {
        borderBottom: '1px solid #ddd',
      }
    }
  },
})(Card);

interface ContentWrapperProps {
  isOpen: boolean;
}

export const ListItemCardContent = styled.div<ContentWrapperProps>`
  display: flex;
  flex-direction: row;
  padding: ${props => props.isOpen ? '12px' : '16px'};
  position: relative;
`;
