import _ from 'lodash';
import React from 'react';
import { ResponsiveBar, Layer, BarCustomLayer } from '@nivo/bar';
import styled from 'styled-components';
import { DOW_TRANSFORM, FILTERS } from '../../../lib/constants';

interface ActivityChartProps {
  data: any;
  filter: string;
  loading: boolean;
  error: any;
}

const ChartWrapper = styled.div`
  height: 200px;
  display: block;
  position: relative;
  width: 100%;
`;

enum BarLayerType {
  Grid = 'grid',
  Axes = 'axes',
  Bars = 'bars',
  Markers = 'markers',
  Legends = 'legends',
}

const ActivityChart: React.FC<ActivityChartProps> = ({ data, error, loading, filter }) => {
  const formattedData = [];
  let goal: number = _.get(data, 'goal', 30);

  _.each(DOW_TRANSFORM, (label, key) => {
    formattedData.push({ dow: label, Group: _.get(data, ['g', key], 0), You: _.get(data, ['p', key], 0) });
  });

  formattedData.push({ You: 0, Group: 0, Goal: goal, dow: '' });

  // @ts-ignore
  let personalMax = _.maxBy(formattedData, (item: any) => item.You).You;
  // @ts-ignore
  let groupMax = _.maxBy(formattedData, (item: any) => item.Group).Group;
  const max = Math.max(personalMax, groupMax, goal);
  const maxValue = Math.max(personalMax, groupMax);

  const maxY = maxValue > goal ? 0 : 144 *  Math.abs(maxValue - goal) / Math.max(maxValue, goal);
  const goalY = goal > maxValue ? 0 : 144 *  Math.abs(maxValue - goal) / Math.max(maxValue, goal);
  const maxTextY = maxY > goalY ? maxY + 4 : maxY - 18;
  const getGoal: BarCustomLayer = function(props: any) {
    if (!_.get(data, 'goal') && (loading || error)) return (
      <g>
        <rect transform={`translate(0, ${props.height / 2 - 56 / 2})`} x="0" y="0" fill="rgba(0, 0, 0, 0.05)" width={props.width} height="26"></rect>

        <text transform={`translate(${props.width / 2 + (error ? 163 : 66) / 2}, ${props.height / 2 - 47 / 2})`} x="0" y="0" textAnchor="end" fill="rgba(255, 255, 255, 0.65)">
          <tspan className="text" x="0" dx="0" dy="13" fontSize="14">{error ? 'Error Loading Activities' : 'Loading...'}</tspan>
        </text>
      </g>
    );

    return (
      <g>
        {maxValue > 0 && maxValue !== goal && (
          <text transform={`translate(${props.width - props.width * 0.8}, ${maxTextY})`} x="0" y="0" textAnchor="end" fill="rgba(255, 255, 255, 0.65)">
            <tspan className="text" x="0" dx="0" dy="13" fontSize="14">{maxValue} min</tspan>
          </text>
        )}
        {maxValue > 0 && maxValue !== goal && (
          <line x1="0" x2={props.width - props.width * 0.8} y1={maxY} y2={maxY} stroke="#000000" strokeWidth="1" style={{ stroke: 'rgba(255, 255, 255, 0.65)', strokeWidth: 1 }}></line>
        )}

        {maxValue === 0 && (
          <rect transform={`translate(0, ${props.height / 2 - 56 / 2})`} x="0" y="0" fill="rgba(0, 0, 0, 0.05)" width={props.width} height="26"></rect>
        )}

        {maxValue === 0 && (
          <text transform={`translate(${props.width / 2 + 209 / 2}, ${props.height / 2 - 47 / 2})`} x="0" y="0" textAnchor="end" fill="rgba(255, 255, 255, 0.65)">
            <tspan className="text" x="0" dx="0" dy="13" fontSize="14">Weekly Goal: {goal} Minutes Daily</tspan>
          </text>
        )}

        {/* Goal */}
        {maxValue > 0 && (
          <text transform={`translate(${props.width - 8}, ${Math.abs(goalY - 8)})`} x="0" y="0" textAnchor="end" fill="rgba(0, 0, 0, 0.65)">
            <tspan className="text" x="0" dx="0" dy="0" fontSize="16" fontWeight="bold" fontFamily="Tahoma">Goal</tspan>
            <tspan className="text" x="0" dx="0" dy="13" fontSize="14">{goal}</tspan>
            <tspan className="text" x="0" dx="0" dy="10" fontSize="10">min</tspan>
          </text>
        )}
        {maxValue > 0 && (
          <line x1="0" x2={props.width - 50} y1={goalY} y2={goalY} stroke="#000000" strokeWidth="1" style={{ stroke: 'rgba(255, 255, 255, 0.35)', strokeWidth: 2 }}></line>
        )}
      </g>
    )
  };

  const CustomAxisTick = (tick: any) => (
    <g transform={`translate(${tick.x},${tick.y + 22})`}>
      <text
        textAnchor="middle"
        dominantBaseline="middle"
        style={{
          fill: 'rgba(255, 255, 255, 0.8)',
          fontSize: 16,
          fontWeight: 'lighter',
        }}
      >
        {tick.value.charAt(0)}
      </text>
    </g>
  );

  const layers: Layer[] = [BarLayerType.Grid, BarLayerType.Axes, BarLayerType.Bars, BarLayerType.Markers, BarLayerType.Legends, getGoal];

  // @ts-ignore
  const bar = <ResponsiveBar
    data={formattedData}
    groupMode="grouped"
    indexBy="dow"
    keys={filter === FILTERS.GROUP ? ['You', 'Group'] : ['You']}
    margin={{ top: 16, right: 0, bottom: 40, left: 0 }}
    padding={filter === FILTERS.GROUP ? 0.4 : 0.7}
    colors={['#3D8980', '#9EDFD7', 'transparent' ]}
    axisLeft={null}
    enableLabel={false}
    enableGridY={false}
    animate={true}
    motionStiffness={90}
    motionDamping={15}
    maxValue={max}
    axisBottom={{ tickSize: 0, tickPadding: 16, renderTick: CustomAxisTick }}
    markers={[
      {
        axis: 'y',
        value: 0,
        lineStyle: { stroke: 'rgba(255, 255, 255, .35)', strokeWidth: 2 },
      },
    ]}
    layers={layers}
  />;

  return <ChartWrapper>{bar}</ChartWrapper>;
};

export default ActivityChart;

// <text transform={`translate(${props.width - props.width * 0.8}, ${groupMaxTextY})`} x="0" y="0" textAnchor="end" fill="rgba(0, 0, 0, 0.65)">
//   <tspan className="text" x="0" dx="0" dy="13" fontSize="14">{groupMax} min</tspan>
// </text>
// <line x1="0" x2={props.width - props.width * 0.8} y1={groupMaxY} y2={groupMaxY} stroke="#000000" stroke-width="1" style={{ stroke: 'rgba(0, 0, 0, 0.35)', strokeWidth: 1 }}></line>
// <text transform={`translate(${props.width - props.width * 0.8}, ${personalMaxTextY})`} x="0" y="0" textAnchor="end" fill="rgba(255, 255, 255, 0.65)">
//   <tspan className="text" x="0" dx="0" dy="13" fontSize="14">{personalMax / numMembers} min</tspan>
// </text>
// <line x1="0" x2={props.width - props.width * 0.8} y1={personalMaxY} y2={personalMaxY} stroke="#000000" stroke-width="1" style={{ stroke: 'rgba(255, 255, 255, 0.65)', strokeWidth: 1 }}></line>
