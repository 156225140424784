import styled from 'styled-components';

export const PrimaryContent = styled.div`
  
`;

interface SecondaryContentProps {
  small?: boolean;
}

export const SecondaryContent = styled.div<SecondaryContentProps>`
  font-size: ${props => props.small ? '12px' : '14px'};
  color: rgba(0, 0, 0, 0.54);
  margin-top: 4px;
  height: ${props => props.small ? '12px' : '14px'};
`;
