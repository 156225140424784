import { gql } from '@apollo/client';

import { getCacheModel, handleCreateMutation } from '../helpers/mutation.helper';
import { createCacheModifier } from '../cache/basic.cache';

export const MESSAGE_FIELDS = gql`
  fragment MessageFields on Message {
    _id
    content
    user {
      _id
      name
    }
    sentAt
  }
`;

export const NEW_MESSAGE_SUBSCRIPTION = gql`
  subscription onMessageAdded($entity: String!, $parentId: ID!, $userId: ID!) {
    messageAdded(entity: $entity, parentId: $parentId, userId: $userId) {
      entity
      parentId
      message {
        ...MessageFields
      }
    }
  }

  ${MESSAGE_FIELDS}
`;

export const SEND_MESSAGE = gql`
  mutation SendMessage($entity: String!, $parentId: ID!, $content: String!) {
    sendMessage(entity: $entity, parentId: $parentId, content: $content) {
      ...MessageFields
    }
  }

  ${MESSAGE_FIELDS}
`;

// @ts-ignore
export const handleSendMessage = ({ mutationResponse, mutationVariables, ...rest }) => {
  // @ts-ignore
  handleCreateMutation({
    ...rest,
    mutationVariables,
    mutationResponse: { __typename: 'Message', ...mutationResponse },
    mutationResponseRef: 'sendMessage',
    update: (cache: any, { data }: any) => {
      const model = getCacheModel(cache, mutationVariables.entity, mutationVariables.parentId);

      cache.modify({
        id: cache.identify(model),
        fields: {
          messages: createCacheModifier({
            cache,
            createdDoc: data.sendMessage,
            modelName: 'Message',
            fragment: MESSAGE_FIELDS,
            fragmentName: 'MessageFields'
          })
        }
      });
    }
  });
};

export const MARK_CONVERSATION_READ = gql`
  mutation MarkConversationRead($entity: String!, $parentId: ID!) {
    markConversationRead(entity: $entity, parentId: $parentId) {
      ... on Activity {
        _id
        unreadMsg
      }
      ... on Meal {
        _id
        unreadMsg
      }
    }
  }
`;
