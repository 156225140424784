import withStyles from '@material-ui/core/styles/withStyles';
import IconButton from '@material-ui/core/IconButton';

const BackWrapper = withStyles({
  root: {
    position: 'absolute',
    left: '0px',
    top: '6px'
  }
})(IconButton);

export default BackWrapper;
