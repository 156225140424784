import _ from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';

// Material UI
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';

// Content
import { CreateGroupFormProps } from './create-group-form.interfaces';
import messages from './create-group-form.messages';

const CreateGroupForm: React.FC<CreateGroupFormProps> = ({ formik }) => {
  const {
    handleSubmit,
    handleBlur,
    handleChange,
    isSubmitting,
    errors,
    touched,
    values,
  } = formik;

  return (
    <form onSubmit={handleSubmit}>
      <Box display="flex" flexDirection="column">

        {/* Group Name */}
        <FormControl component="div" error={!!errors.name && touched.name}>
          <InputLabel htmlFor="create_group__name">
            <FormattedMessage {...messages.labelName} />
          </InputLabel>
          <Input
            id="create_group__name"
            type="text"
            name="name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
            aria-describedby="create_group__name__error_text"
            autoComplete="off"
          />
          {_.isString(errors.name) && touched.name && (
            <FormHelperText id="create_group__name__error_text">
              {errors.name}
            </FormHelperText>
          )}
        </FormControl>

        <Button type="submit" disabled={isSubmitting} variant="contained">
          <FormattedMessage {...messages.actionCreate} />
        </Button>
      </Box>
    </form>
  );
};

export default CreateGroupForm;
