import _ from 'lodash';
import React, { ReactNode } from 'react';

// Material UI
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import GroupIcon from '@material-ui/icons/Group';
import NotificationsIcon from '@material-ui/icons/Notifications';
import withStyles from '@material-ui/core/styles/withStyles';

// Components
import BottomMenu from '../BottomMenu/bottom-menu.component';
import FilterSwitch from './filter-switch.component';

interface AppLayoutProps {
  children: ReactNode;
  maxWidth: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined;
  heading?: string;
  activeTab: string;
  onChangeTab: Function;
  onSignOut: ClickHandler;
  user?: IUser | null;
  group?: IGroup | null;
  history: any;
}

interface AppLayoutState {
  showDrawer: boolean;
}

const NwContainer = withStyles({
  root: {
    padding: 0,
    height: '100%',
  }
})(Container);

const NwToolbar = withStyles({
  root: {
    textTransform: 'uppercase',
    fontWeight: 'lighter',
    color: 'rgba(255, 255, 255, 0.86)',
}
})(Toolbar);

const NwAppBar = withStyles({
  root: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    paddingTop: 'max(0px, env(safe-area-inset-top))',
  }
})(AppBar);

class AppLayout extends React.Component<AppLayoutProps, AppLayoutState>  {
  state: AppLayoutState = {
    showDrawer: false,
  };

  toggleDrawer = (showDrawer: boolean) => {
    this.setState({ showDrawer });
  };

  render() {
    const { children, maxWidth, heading, activeTab, onChangeTab, onSignOut, user, group, history } = this.props;
    const { showDrawer } = this.state;

    return (
      <NwContainer maxWidth={maxWidth}>
        <NwAppBar position="static">
          <NwToolbar>
            <IconButton edge="start" color="inherit" aria-label="menu" onClick={() => this.toggleDrawer(true)}>
              <MenuIcon/>
            </IconButton>
            <Box flexGrow={1}>
              <Typography variant="h6">
                {heading}
              </Typography>
            </Box>
            {_.includes(['meals', 'activities'], activeTab) && <FilterSwitch />}
          </NwToolbar>
        </NwAppBar>
        <Drawer open={showDrawer} onClose={() => this.toggleDrawer(false)}>
          <List style={{ width: '200px', paddingTop: 'env(safe-area-inset-top)' }}>
            {user && (
              <ListItem>
                <ListItemText primary={user.name} secondary={group && group.name} />
              </ListItem>
            )}
            {user && user.isAdmin && (
              <ListItem button key="signOut" onClick={() => history.push('/groups')}>
                <ListItemIcon><GroupIcon /></ListItemIcon>
                <ListItemText primary="Groups" />
              </ListItem>
            )}
            <ListItem button key="notifications" onClick={() => history.push('/notifications')}>
              <ListItemIcon><NotificationsIcon /></ListItemIcon>
              <ListItemText primary="Notifications" />
            </ListItem>
            <ListItem button key="signOut" onClick={onSignOut}>
              <ListItemIcon><ExitToAppIcon /></ListItemIcon>
              <ListItemText primary="Sign Out" />
            </ListItem>
          </List>
        </Drawer>
        {children}
        <BottomMenu activeTab={activeTab} onChangeTab={onChangeTab} group={group} />
      </NwContainer>
    );
  }
}

export default AppLayout;


