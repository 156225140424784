import { gql } from '@apollo/client';
import { handleCreateMutation } from '../helpers/mutation.helper';

export const GET_CHECKINS = gql`
  query GetCheckIns {
    checkIns {
      _id
      bloodPressureOver
      bloodPressureUnder
      bloodSugar
      waistCircumference
      hasEaten
      createdAt
    }
  }
`;

export const ADD_CHECKIN = gql`
  mutation AddCheckIn($data: CheckInInput!) {
    createCheckIn(data: $data) {
      _id
      bloodPressureOver
      bloodPressureUnder
      bloodSugar
      waistCircumference
      hasEaten
      createdAt
    }
  }
`;

// @ts-ignore
export const handleCheckInCreation = ({ mutationResponse, ...rest }) => {
  // @ts-ignore
  handleCreateMutation({
    ...rest,
    mutationResponse: { __typename: 'CheckIn', ...mutationResponse },
    mutationResponseRef: 'createCheckIn',
    cacheQuery: GET_CHECKINS,
    cacheQueryRef: 'checkIns',
  });
};

export const REMOVE_CHECKIN = gql`
  mutation RemoveCheckIn($_id: ID!) {
    removeCheckIn(_id: $_id) {
      _id
    }
  }
`;
