import React from 'react';

// Material UI
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// Components
import GroupListItemSummary from '../GroupListItemSummary/group-list-item-summary.component';
import GroupListItemDetails from '../GroupListItemDetails/group-list-item-details.component';

interface GroupListProps {
  loading: boolean;
  error?: Error;
  groups: Array<IGroup>;
}

const GroupList: React.FC<GroupListProps> = ({ loading, error, groups }) => {
  const [expanded, setExpanded] = React.useState('');

  const handleChange = (panel: string) => (event: any, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : '');
  };

  let listItems = groups.map(group => (
    <ExpansionPanel key={group._id} expanded={expanded === group._id} onChange={handleChange(group._id)}>
      <ExpansionPanelSummary
        id={group._id}
        aria-controls="panel1bh-content"
        expandIcon={<ExpandMoreIcon />}
      >
        <GroupListItemSummary group={group} />
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <GroupListItemDetails group={group} />
      </ExpansionPanelDetails>
    </ExpansionPanel>
  ));

  if (listItems.length === 0) {
    listItems = [<ExpansionPanel disabled><ExpansionPanelSummary id="none">No groups created</ExpansionPanelSummary></ExpansionPanel>];
  }

  if (loading && listItems.length === 0) {
    listItems = [<ExpansionPanel disabled><ExpansionPanelSummary id="loading">Loading groups...</ExpansionPanelSummary></ExpansionPanel>];
  }

  if (error) {
    listItems = [<ExpansionPanel disabled><ExpansionPanelSummary id="error">Couldn't load groups, please try again later</ExpansionPanelSummary></ExpansionPanel>];
  }

  return <div>{listItems}</div>;
};

export default GroupList;
