import _ from 'lodash';
import React, { ReactNode } from 'react';
import { ApolloProvider } from '@apollo/client';
import { ApolloClient } from '@apollo/client';

import createApolloClient from '../../../lib/createApolloClient';
import AuthApiService from '../../../lib/utils/auth-api.service';
import ErrorPage from '../../../components/_pages/ErrorPage/error-page.container';
import LoadingPage from '../../../components/_pages/LoadingPage/loading-page.container';

interface PersistedApolloState {
  bootstrapped: boolean;
  client?: ApolloClient<any>;
  error?: any;
}

interface PersistedApolloProps {
  children: ReactNode;
}

export default class PersistedApolloProvider extends React.Component<PersistedApolloProps, PersistedApolloState> {
  state: PersistedApolloState = {
    bootstrapped: false,
  };

  componentDidMount() {
    AuthApiService.refreshToken()
      .then(result => createApolloClient(_.get(result, 'user')))
      .then(client => {
        this.setState({ bootstrapped: true, client });
      })
      .catch(err => {
        this.setState({ error: true });
      });
  }

  render() {
    const { bootstrapped, error, client } = this.state;
    const { children } = this.props;

    if (error) return <ErrorPage />

    return bootstrapped && client ? (
      <ApolloProvider client={client}>{children}</ApolloProvider>
    ) : (
      <LoadingPage />
    );
  }
}
