import { Http } from '@capacitor-community/http';

import { BASE_API_URL, HTTP_PROTOCOL } from '../constants';

class RestService {
  static async post(endpoint: string, payload = {}) {
    return Http.post({
      url: `${HTTP_PROTOCOL}://${BASE_API_URL}${endpoint}`,
      headers: {
        'Content-Type': 'application/json',
      },
      webFetchExtra: {
        credentials: 'include',
      },
      data: payload
    }).then((response: any) => response.status === 200 ? response.data : response)
  }
}

export default RestService;
