import _ from 'lodash';
import relativeTime from 'dayjs/plugin/relativeTime';
import dayjs from 'dayjs';
import React from 'react';

// Material UI
import IconButton from '@material-ui/core/IconButton';
import SendIcon from '@material-ui/icons/Send';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import DeleteIcon from '@material-ui/icons/Delete';

// Components
import { ListItemTertiaryAction } from '../../member/MemberList/member-list.styled';
import { List } from '@material-ui/core';

interface InviteListProps {
  invites: Array<IInvite>;
  onCancelInvite: Function;
  onResendInvite: Function;
}

dayjs.extend(relativeTime);

const InviteList: React.FC<InviteListProps> = ({ invites, onCancelInvite, onResendInvite }) => (
  <List>
    {_.map(invites, (invite: IInvite) => (
      <ListItem key={invite._id}>
        <ListItemText
          primary={invite.email || invite.phone}
          secondary={`Sent ${dayjs(invite.sentAt).fromNow()}`}
        />
        <ListItemTertiaryAction>
          <IconButton aria-label="delete" onClick={() => onResendInvite(invite._id)}>
            <SendIcon />
          </IconButton>
        </ListItemTertiaryAction>
        <ListItemSecondaryAction>
          <IconButton aria-label="delete" onClick={() => onCancelInvite(invite._id)}>
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    ))}
    {!invites.length && (
      <ListItem>
        <ListItemText primary="None" />
      </ListItem>
    )}
  </List>
);

export default InviteList;
