import _ from 'lodash';
import React from 'react';
import { useQuery } from '@apollo/client';

// Data
import { GET_GROUPS } from '../../lib/queries/group.queries';

// Containers
import CreateGroupContainer from '../../containers/group/CreateGroupContainer/create-group.container';

// Components
import GroupList from '../../components/group/GroupList/group-list.component';

const ManageGroupsPageContainer: React.FC = () => {
  const { loading, error, data } = useQuery(GET_GROUPS, { fetchPolicy: 'cache-and-network' });
  const groups = _.get(data, 'groups', []);

  return (
    <div>
      <GroupList {...{ loading, error, groups }} />
      <CreateGroupContainer />
    </div>
  );
};

export default ManageGroupsPageContainer;
