import React from 'react';
import styled from 'styled-components';

import ManageMembersContainer from '../../../containers/member/ManageMembersContainer/manage-members.container';

interface GroupListItemDetailsProps {
  group: IGroup;
}

const Wrapper = styled.div`
  width: 100%;
`;

const GroupListItemDetails: React.FC<GroupListItemDetailsProps> = ({ group }) => {
  return (
    <Wrapper>
      <ManageMembersContainer group={group} />
    </Wrapper>
  );
};

export default GroupListItemDetails;
