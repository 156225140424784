import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';

// Data
import ConversationContainer from '../../containers/conversation/ConversationContainer/conversation.container';
import { getAuthUser } from '../../lib/services/auth.service';
import { ENTITIES } from '../../lib/constants';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ChatPageContainer: React.FC = () => {
  const user = getAuthUser();

  return (
    <Wrapper>
      {user && <ConversationContainer isPublic parentId={_.get(user, 'state.activeGroup')} entity={ENTITIES.GROUP} />}
      {!user && <p>Loading...</p>}
    </Wrapper>
  );
};

export default ChatPageContainer;
