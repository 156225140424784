import { createMuiTheme, responsiveFontSizes  } from '@material-ui/core/styles';

export const mainColor = '#A2DEAA';
export const borderColor = '#ddd';
export const borderRadius = '4px';

export const dangerColor = '#ed4337';
export const warnColor = '#ffcf33';
export const successColor = '#4bb543';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: mainColor,
      light: '#A2DEAA',
    },
    secondary: { main: '#43C2C1' },
  },
  typography: {
    fontFamily: ['Montserrat', 'sans-serif'].join(',')
  },
});

export default responsiveFontSizes(theme);
