import { gql } from '@apollo/client';
import { handleDeleteMutation, handleUpdateMutation } from '../helpers/mutation.helper';

export const NEW_MEMBER_FRAGMENT = gql`
    fragment NewMember on Member {
      _id
      role
      user {
        _id
        name
        email
        isAdmin
      }
    }
`;

export const ADD_MEMBER = gql`
  mutation AddMember($groupId: ID!, $email: String!) {
    addMember(groupId: $groupId, email: $email) {
      ...NewMember
    }
  }

  ${NEW_MEMBER_FRAGMENT}
`;

export const SET_MEMBER_ROLE = gql`
  mutation SetMemberRole($_id: ID!, $role: String!) {
    setMemberRole(_id: $_id, role: $role) {
      _id
      role
    }
  }
`;

// @ts-ignore
export const handleMemberUpdate = ({ mutationResponse, ...rest }) => {
// @ts-ignore
  handleUpdateMutation({
    ...rest,
    mutationResponse: { __typename: 'Member', ...mutationResponse },
    mutationResponseRef: 'setMemberRole',
  });
};

export const REMOVE_MEMBER = gql`
  mutation RemoveMember($_id: ID!) {
    removeMember(_id: $_id) {
      _id
    }
  }
`;

// @ts-ignore
export const handleMemberDeletion = ({ ...rest }) => {
  // @ts-ignore
  handleDeleteMutation({
    ...rest,
    mutationResponseRef: 'removeMember',
  });
};
