import _ from 'lodash';
import { useMutation } from '@apollo/client';

import { ADD_MEMBER, NEW_MEMBER_FRAGMENT, REMOVE_MEMBER } from '../queries/member.queries';
import { createCacheModifier, removeCacheModifier } from '../cache/basic.cache';

export const buildCreateMember = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [mutation, { data, ...rest }] = useMutation(ADD_MEMBER);
  const member = _.get(data, 'addMember');

  const createMember = (groupId: string, email: string) => mutation({
    variables: { groupId, email },
    update: (cache: any, { data }: any) => {
      const createdMember = _.get(data, 'addMember');

      cache.modify({
        fields: {
          members: createCacheModifier({
            cache,
            createdDoc: createdMember,
            modelName: 'Member',
            fragment: NEW_MEMBER_FRAGMENT,
            fragmentName: 'NewMember',
          })
        }
      });
    }
  });

  return { createMember, member, ...rest };
};

export const buildRemoveMember = (group: IGroup) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [mutation, { data, ...rest }] = useMutation(REMOVE_MEMBER);

  const removeMember = (_id: string) =>
    mutation({
      variables: { _id },
      update: (cache: any) => {
        cache.modify({
          id: cache.identify(group),
          fields: { members: removeCacheModifier(cache, _id) }
        });
      }
    });

  return { removeMember, ...rest };
}
