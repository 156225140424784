import _ from 'lodash';
import React from 'react';
import { createIntl, createIntlCache, FormattedMessage, RawIntlProvider } from 'react-intl';

// Material UI
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import withStyles from '@material-ui/core/styles/withStyles';

// Components
import ChecklistItem from '../ChecklistItem/checklist-item.component';
import { NwFormControl, NwFormInput, NwFormLabel } from '../../misc/Form/form.components';
import messages from './check-in-form.messages';
import { CheckInFormProps } from '../../../pages/CheckInPage/check-in-page.interfaces';
import ColourPrimaryButton from '../../misc/ColourPrimaryButton/colour-primary-button.component';

const SmallFormControlLabel = withStyles({
  label: {
    fontSize: '15px'
  }
})(FormControlLabel);

const CheckInForm: React.FC<CheckInFormProps> = ({ formik }) => {
  const {
    handleSubmit,
    handleBlur,
    handleChange,
    isSubmitting,
    errors,
    values,
    dirty
  } = formik;

  const isValid = _.keys(errors).length === 0;

  const cache = createIntlCache();
  // @ts-ignore
  const intl = createIntl({ locale: 'en', messages }, cache);

  return (
    <form style={{ height: '100%' }} onSubmit={handleSubmit}>
      <RawIntlProvider value={intl}>
        <div style={{ top: 0, left: 0, width: '54px', height: '100%', background: '#FAFAFA', position: 'absolute', zIndex: 1 }} />
        <div style={{ position: 'absolute', width: '29px', top: 0, left: 0, zIndex: 2, height: '100%', borderRight: '1px dashed #DDD' }} />
        <div style={{ position: 'absolute', zIndex: 3 }}>
          <ChecklistItem
            isComplete={dirty && !errors.bloodPressureOver && !errors.bloodPressureUnder}
            header={intl.formatMessage(messages.labelBloodPressure)}
            helpUrl="https://storage.googleapis.com/norwest/NorWest%20Hans%20Kai%20-%20Measure%20Blood%20Pressure.pdf"
          >
            <NwFormControl component="div">
              <NwFormLabel htmlFor="check_in__blood_pressure_over">
                <FormattedMessage {...messages.labelSystolic} />
              </NwFormLabel>
              <NwFormInput
                id="check_in__blood_pressure_over"
                type="number"
                name="bloodPressureOver"
                autoComplete="off"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.bloodPressureOver}
              />
            </NwFormControl>
            <NwFormControl component="div">
              <NwFormLabel htmlFor="check_in__blood_pressure_under">
                <FormattedMessage {...messages.labelDiastolic} />
              </NwFormLabel>
              <NwFormInput
                id="check_in__blood_pressure_under"
                type="number"
                name="bloodPressureUnder"
                autoComplete="off"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.bloodPressureUnder}
              />
            </NwFormControl>
          </ChecklistItem>
          <ChecklistItem
            isComplete={dirty && !errors.bloodSugar}
            header={intl.formatMessage(messages.labelBloodSugar)}
            helpUrl="https://storage.googleapis.com/norwest/NorWest%20Hans%20Kai%20-%20Taking%20Blood%20Sugar.pdf"
          >
            <NwFormControl component="div" style={{ marginBottom: '8px', width: '215px' }}>
              <NwFormLabel htmlFor="check_in__blood_sugar" style={{ textTransform: 'none' }}>
                <FormattedMessage {...messages.labelBloodSugarMeasurement} />
              </NwFormLabel>
              <NwFormInput
                id="check_in__blood_sugar"
                type="number"
                name="bloodSugar"
                autoComplete="off"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.bloodSugar}
              />
            </NwFormControl>
            <NwFormControl component="div" style={{ marginBottom: '8px' }}>
              <SmallFormControlLabel
                control={<Checkbox checked={values.hasEaten} size="medium" onChange={handleChange} name="hasEaten" />}
                label={intl.formatMessage(messages.labelHaveEaten)}
              />
            </NwFormControl>
          </ChecklistItem>
          <ChecklistItem
            isComplete={dirty && !errors.waistCircumference}
            header={intl.formatMessage(messages.labelWaistCircumference)}
            helpUrl="https://storage.googleapis.com/norwest/NorWest%20Hans%20Kai%20-%20Measure%20Waist%20Circumference.pdf"
          >
            <NwFormControl component="div">
              <NwFormLabel htmlFor="check_in__waist_circumference" style={{ textTransform: 'none' }}>
                <FormattedMessage {...messages.labelWaistCircumferenceMeasurement} />
              </NwFormLabel>
              <NwFormInput
                id="check_in__waist_circumference"
                type="number"
                name="waistCircumference"
                autoComplete="off"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.waistCircumference}
              />
            </NwFormControl>
          </ChecklistItem>
          <Box display="flex" style={{ marginLeft: `54px` }} mb={2}>
            <ColourPrimaryButton type="submit" disabled={isSubmitting || !isValid || !dirty} variant="contained" style={{ maxWidth: 'none' }}>
              Complete Check-In
            </ColourPrimaryButton>
          </Box>
        </div>
      </RawIntlProvider>
    </form>
  );
};

export default CheckInForm;
