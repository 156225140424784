import _ from 'lodash';
import uuidv4 from 'uuid/v4';

import { ENTITIES } from '../constants';

// Queries
import { GET_GROUP } from '../queries/group.queries';
import { GET_ACTIVITY } from '../queries/activity.queries';
import { GET_MEAL } from '../queries/meal.queries';

interface CreateMutationProps {
  mutation: any;
  mutationVariables: any;
  mutationResponse: any;
  mutationResponseRef: string;
  cacheQuery: any;
  cacheQueryVariables: any;
  cacheQueryRef: string;
  update?: Function;
  prepend?: boolean;
}

export const handleCreateMutation = ({
  mutation,
  mutationVariables,
  mutationResponse,
  mutationResponseRef,
  cacheQuery,
  cacheQueryVariables,
  cacheQueryRef,
  update,
  prepend,
  ...rest
}: CreateMutationProps) => {
  return mutation({
    variables: mutationVariables,
    optimisticResponse: {
      __typename: 'Mutation',
      [mutationResponseRef]: {
        _id: uuidv4(),
        ...mutationResponse,
      },
    },
    update: update ? update : (cache: any, { data }: any) => {
      try {
        const result = cache.readQuery({
          query: cacheQuery,
          variables: cacheQueryVariables,
        });

        cache.writeQuery({
          query: cacheQuery,
          variables: cacheQueryVariables,
          data: {
            [cacheQueryRef]: prepend
              ? result[cacheQueryRef].concat([data[mutationResponseRef]])
              : [data[mutationResponseRef]].concat(result[cacheQueryRef]),
          },
        });
      }
      catch (err) {
        console.warn(err);
      }
    },
    ...rest,
  });
};

interface UpdateMutationProps {
  mutation: any;
  mutationVariables: any;
  mutationResponse: any;
  mutationResponseRef: string;
}

export const handleUpdateMutation = ({
  mutation,
  mutationVariables,
  mutationResponse,
  mutationResponseRef,
  ...rest
}: UpdateMutationProps) => {
  const props: any = {
    variables: mutationVariables,
    ...rest,
  };

  if (mutationResponse) {
    props.optimisticResponse = {
      __typename: 'Mutation',
      [mutationResponseRef]: mutationResponse,
    };
  }

  return mutation(props);
};

interface DeleteMutationProps {
  mutation: any;
  mutationVariables: any;
  mutationResponse: any;
  mutationResponseRef: string;
  cacheQuery: any;
  cacheQueryVariables: any;
  cacheQueryRef: string;
}

export const handleDeleteMutation = ({
  mutation,
  mutationVariables,
  mutationResponse,
  mutationResponseRef,
  cacheQuery,
  cacheQueryVariables,
  cacheQueryRef,
  ...rest
}: DeleteMutationProps) => {
  return mutation({
    variables: mutationVariables,
    optimisticResponse: {
      __typename: 'Mutation',
      [mutationResponseRef]: {
        ...mutationVariables
      },
    },
    update: (cache: any) => {
      const { _id } = mutationVariables;

      const queryResult = cache.readQuery({
        query: cacheQuery,
        variables: cacheQueryVariables,
      });

      const cacheResult = queryResult && queryResult[cacheQueryRef]
        ? { [cacheQueryRef]: _.filter(queryResult[cacheQueryRef], model => model._id !== _id) }
        : queryResult;

      cache.writeQuery({
        query: cacheQuery,
        variables: cacheQueryVariables,
        data: cacheResult,
      });
    },
    ...rest,
  });
};

export const getQuery = (entity?: string) => {
  let query = GET_GROUP;
  let queryRef = 'group';

  switch (entity) {
    case ENTITIES.ACTIVITY:
      query = GET_ACTIVITY;
      queryRef = 'activity';
      break;
    case ENTITIES.MEAL:
      query = GET_MEAL;
      queryRef = 'meal';
      break;
  }

  return { query, queryRef };
};

export const getCacheModel = (cache: any, entity?: string, parentId?: string) => {
  const { query, queryRef } = getQuery(entity);
  const modelResult: any = cache.readQuery({ query, variables: { _id: parentId } });
  return _.get(modelResult, queryRef);
};
