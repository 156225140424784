import _ from 'lodash';
import React from 'react';
import { useMutation, useQuery } from '@apollo/client';

// Components
import Conversation from '../../../components/conversation/Conversation/conversation.component';
import { handleSendMessage, SEND_MESSAGE } from '../../../lib/queries/message.queries';
import { GET_ACTIVITY } from '../../../lib/queries/activity.queries';
import { GET_MEAL } from '../../../lib/queries/meal.queries';
import { GET_GROUP } from '../../../lib/queries/group.queries';
import { getAuthUser } from '../../../lib/services/auth.service';
import { buildMarkConversationRead, subscribeToMessages } from '../../../lib/services/message.service';

interface ConversationProps {
  hidden?: boolean;
  entity: string;
  parentId: string;
  isPublic: boolean;
  type?: string;
}

const ConversationContainer: React.FC<ConversationProps> = ({ hidden, entity, parentId, isPublic, type }) => {
  let query = GET_GROUP;
  let queryRef = 'group';

  switch (entity) {
    case 'Activity':
      query = GET_ACTIVITY;
      queryRef = 'activity';
      break;
    case 'Meal':
      query = GET_MEAL;
      queryRef = 'meal';
      break;
  }

  const { data: modelResult, loading, error } = useQuery(query, { skip: !parentId, variables: { _id: parentId }, fetchPolicy: 'cache-and-network' });
  const model = _.get(modelResult, queryRef);

  const user = getAuthUser();

  const markConversationRead = buildMarkConversationRead();
  subscribeToMessages({ entity, parentId, onMarkConversationRead: markConversationRead });
  const onMarkConversationRead = () => {
    markConversationRead(entity, parentId);
  };

  const [sendMessage] = useMutation(SEND_MESSAGE);
  function onSendMessage(content: string) {
    handleSendMessage({
      mutation: sendMessage,
      mutationVariables: { content, parentId, entity },
      mutationResponse: { content, sentAt: new Date(), user }
    });
  }

  return <Conversation onMarkConversationRead={onMarkConversationRead} type={type} isPublic={isPublic} loading={loading} error={error} localUser={user} hidden={hidden} messages={model ? model.messages : []} onSendMessage={onSendMessage} />;
};

export default ConversationContainer;
