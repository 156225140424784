import { defineMessages } from 'react-intl';

export const scope = 'app.components.CheckInPage';

export default defineMessages({
  instructions: {
    id: `${scope}.steps`,
    defaultMessage: 'Follow the steps below to record your current health indicators'
  }
});
