import _ from 'lodash';
import { useFormik } from 'formik';
import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { object, string, boolean } from 'yup';

// Data
import {
  GET_MEAL,
  ADD_MEAL,
  handleMealCreation,
  handleMealUpdate,
  UPDATE_MEAL
} from '../../../lib/queries/meal.queries';

// Components
import CreateMealForm from '../../../components/meal/CreateMealForm/create-meal-form.component';
import {
  CreateMealFormValues
} from '../../../components/meal/CreateMealForm/create-meal-form.interfaces';
import { DATE_FORMAT, FILTERS, MEAL_TYPES } from '../../../lib/constants';
import { activeWeek } from '../../../lib/queries/app.queries';
import { getFeedFilter } from '../../../lib/services/app.service';
import { getAuthUser } from '../../../lib/services/auth.service';

function getCurrentMealType() {
  const splitAfternoon = 11;
  const splitEvening = 17;
  const currentHour = parseFloat(dayjs().format("HH"));

  if (currentHour >= splitAfternoon && currentHour <= splitEvening) return MEAL_TYPES.LUNCH;
  if (currentHour >= splitEvening) return MEAL_TYPES.DINNER;
  return MEAL_TYPES.BREAKFAST;
}

const INITIAL_FORM_VALUES = {
  name: '',
  description: '',
  date: dayjs().format('YYYY-MM-DD'),
  type: getCurrentMealType(),
  isPublic: true,
};

interface CreateMealContainerProps {
  onCancel: ClickHandler;
  modelId?: string;
  isShown?: boolean;
}

const CreateMealContainer: React.FC<CreateMealContainerProps> = ({ isShown, onCancel, modelId }) => {
  const user = getAuthUser();

  const filter = getFeedFilter();

  const [addMeal] = useMutation(ADD_MEAL);
  const [updateMeal] = useMutation(UPDATE_MEAL);
  const { data: mealResult } = useQuery(GET_MEAL, {
    variables: { _id: modelId },
    skip: !modelId
  });
  const meal = _.get(mealResult, 'meal');

  function handleSubmit(values: CreateMealFormValues, { setSubmitting, setValues }: any) {
    const mealWeek = dayjs(values.date, DATE_FORMAT).startOf('week').format(DATE_FORMAT);

    onCancel();

    if (user) {
      // @ts-ignore
      const { _id, name } = user;

      const mutationParams: any = {
        mutationVariables: {mealData: _.omit(values, 'messages')},
        mutationResponse: {
          ..._.omit(values, 'description'),
          user: {__typename: 'User', _id, name },
        },
      };

      if (meal) {
        mutationParams.mutation = updateMeal;
        mutationParams.mutationVariables._id = meal._id;
        mutationParams.mutationResponse._id = meal._id;
        handleMealUpdate(mutationParams);
      } else {
        mutationParams.cacheQueryVariables = filter === FILTERS.GROUP ? {groupId: _.get(user, 'state.activeGroup')} : {};
        mutationParams.cacheQueryVariables.startDate = mealWeek;
        mutationParams.mutation = addMeal;
        mutationParams.prepend = true;
        handleMealCreation(mutationParams);
      }
    }

    activeWeek(mealWeek);
    setSubmitting(false);
    setValues(INITIAL_FORM_VALUES);
  }

  const validationSchema = object().shape({
    type: string().required(),
    name: string().required('What did you eat?'),
    description: string(),
    date: string().required().test('date-format', 'Date should be formatted YYYY-MM-DD', function(value) {
      return value.match(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/g);
    }),
    isPublic: boolean().required(),
  });

  const formik = useFormik({
    initialValues: { ...INITIAL_FORM_VALUES, ..._.omit(meal, ['user', '_id', '__typename']) },
    onSubmit: handleSubmit,
    validationSchema,
    enableReinitialize: true
  });

  // @ts-ignore
  return <CreateMealForm {...{ isShown, formik, onCancel, isEditing: Boolean(meal) }} />;
};

export default CreateMealContainer;
