import _ from 'lodash';
import React from 'react';

interface GroupListItemSummaryProps {
  group: IGroup;
}

const GroupListItemSummary: React.FC<GroupListItemSummaryProps> = ({ group }) => {
  const memberCount = _.get(group, 'members.length', 0);

  return <div>{group.name} - Members: {memberCount}</div>;
};

export default GroupListItemSummary;
