import styled from 'styled-components';

export const ListItem = styled.div`
  background: #fff;
  border-bottom: 1px solid #ddd;
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  &.active {
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10000;
  }
`;
