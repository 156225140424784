import React from 'react';
import qs from 'qs';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

// Material UI
import Typography from '@material-ui/core/Typography';

// Components
import SetPasswordFormContainer from '../../components/user/SetPasswordForm/set-password-form.container';
import SingleFormLayout from '../../components/_pages/_layout/SingleFormLayout/single-form.layout';


const Wrapper = styled.div`
  background: #eee;
  height: 100%;
`;

interface ResetPasswordPageProps {
  location: any;
}

const ResetPasswordPage: React.FC<ResetPasswordPageProps> = ({ location }) => {
  const { token } = qs.parse(location.search, { ignoreQueryPrefix: true });

  return (
    <Wrapper>
      <SingleFormLayout>
        <Typography variant="h4" component="h1" gutterBottom>Reset your password</Typography>
        <SetPasswordFormContainer redirect="/" redirectAction="Sign In" token={String(token)}/>
      </SingleFormLayout>
    </Wrapper>
  );
}

export default withRouter(ResetPasswordPage);
