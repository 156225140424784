import { gql } from '@apollo/client';
import { handleCreateMutation, handleDeleteMutation } from '../helpers/mutation.helper';

export const GET_GROUPS = gql`
  query GetGroups {
    groups {
      _id
      name
      members {
        _id
        role
        user {
          _id
          name
          email
          isAdmin
        }
      }
      invites {
        _id
        email
        phone
        sentAt
      }
    }
  }
`;

export const GET_GROUP = gql`
    query GetGroup($_id: ID!) {
      group(_id: $_id) {
        _id
        name
        unreadMsg
        messages {
          content
          user {
            _id
            name
          }
          sentAt
        }
      }
    }
`;

export const GET_GROUP_SUMMARY = gql`
  query GetGroup($_id: ID!) {
    group(_id: $_id) {
      _id
      name
      unreadMsg
      messages {
        content
        user {
          _id
          name
        }
        sentAt
      }
    }
  }
`;

export const ADD_GROUP = gql`
  mutation AddGroup($groupData: GroupInput!) {
    createGroup(groupData: $groupData) {
      _id
      name
    }
  }
`;

// @ts-ignore
export const handleGroupCreation = ({ mutationResponse, ...rest }) => {
  // @ts-ignore
  handleCreateMutation({
    ...rest,
    mutationResponse: { __typename: 'Group', ...mutationResponse },
    mutationResponseRef: 'createGroup',
    cacheQuery: GET_GROUPS,
    cacheQueryRef: 'groups',
  });
};

export const REMOVE_GROUP = gql`
  mutation RemoveGroup($_id: ID!) {
    removeGroup(_id: $_id) {
      _id
    }
  }
`;

// @ts-ignore
export const handleGroupDeletion = ({ mutationResponse, ...rest }) => {
  // @ts-ignore
  handleDeleteMutation({
    ...rest,
    mutationResponseRef: 'removeGroup',
    cacheQuery: GET_GROUPS,
    cacheQueryRef: 'groups',
  });
};
