import _ from 'lodash';
import React from 'react';

// Material UI
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Divider from '@material-ui/core/Divider';

// Data
import { ROLES } from '../../../lib/constants';

// Components
import { ListItemTertiaryAction } from './member-list.styled';

interface MemberListProps {
  members: Array<IMember>;
  onRemoveMember: Function;
  onChangeRole: Function;
}

const MemberList: React.FC<MemberListProps> = ({ members, onRemoveMember, onChangeRole }) => {
  return (
    <List>
      {members.map(member => [
        <ListItem key={member._id}>
          <ListItemText
            primary={member.user.name || member.user.email}
            secondary={member.user.name ? member.user.email : 'Invite Pending'}
          />
          <ListItemTertiaryAction>
            <FormControl variant="outlined">
              <Select
                value={member ? member.role : 'Member'}
                onChange={e => onChangeRole(member, e.target.value)}
              >
                {_.map(ROLES, role => (
                  <MenuItem key={role} value={role}>{role}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </ListItemTertiaryAction>
          <ListItemSecondaryAction>
            <IconButton aria-label="delete" onClick={() => onRemoveMember(member)}>
              <DeleteIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>,
        <Divider key={`${member._id}_divider`} component="li" />
      ])}
    </List>
  );
};

export default MemberList;
