import iconWALKING from '../assets/icons/icon-walking.png';
import iconRUNNING from '../assets/icons/icon-running.png';
import iconBIKING from '../assets/icons/icon-biking.png';
import iconEXERCISE from '../assets/icons/icon-exercise.png';
import iconYOGA from '../assets/icons/icon-yoga.png';
import iconSWIMMING from '../assets/icons/icon-swimming.png';
import iconHIKING from '../assets/icons/icon-hiking.png';

export const SUPPORT_EMAIL = 'app-support@norwestcoop.ca';

export const BASE_API_URL = process.env.REACT_APP_API_HOST;
export const HTTP_PROTOCOL = process.env.NODE_ENV === 'development' ? 'http' : 'https';
export const GRAPHQL_HTTP_URI = `${HTTP_PROTOCOL}://${BASE_API_URL}/graphql`;
export const WS_PROTOCOL = process.env.NODE_ENV === 'development' ? 'ws' : 'wss';
export const GRAPHQL_WS_URI = `${WS_PROTOCOL}://${BASE_API_URL}/graphql`;

export const ROLES = {
  OWNER: 'OWNER',
  MEMBER: 'MEMBER',
};

export const FILTERS = {
  USER: 'USER',
  GROUP: 'GROUP',
};

export const ACTIVITY_TYPES = {
  WALKING: 'WALKING',
  RUNNING: 'RUNNING',
  BIKING: 'BIKING',
  EXERCISE: 'EXERCISE',
  YOGA: 'YOGA',
  SWIMMING: 'SWIMMING',
  HIKING: 'HIKING',
};

export const TIME_OF_DAY = {
  MORNING: 'MORNING',
  AFTERNOON: 'AFTERNOON',
  EVENING: 'EVENING',
  NIGHT: 'NIGHT',
};

export const ACTIVITY_ICONS = {
  WALKING: iconWALKING,
  RUNNING: iconRUNNING,
  BIKING: iconBIKING,
  EXERCISE: iconEXERCISE,
  YOGA: iconYOGA,
  SWIMMING: iconSWIMMING,
  HIKING: iconHIKING,
};

export const MEAL_TYPES = {
  BREAKFAST: 'BREAKFAST',
  LUNCH: 'LUNCH',
  DINNER: 'DINNER',
  SNACK: 'SNACK',
};

export const DOW_TRANSFORM = {
  '0': 'Su',
  '1': 'Mo',
  '2': 'Tu',
  '3': 'We',
  '4': 'Th',
  '5': 'Fr',
  '6': 'Sa'
};

export const DATE_FORMAT = 'YYYY-MM-DD';

export const INVITE_STATUSES = {
  PENDING: 'PENDING',
  ACCEPTED: 'ACCEPTED',
};

export const ENTITIES = {
  ACTIVITY: 'Activity',
  MEAL: 'Meal',
  GROUP: 'Group'
};
