import { useMutation } from '@apollo/client';

// Queries
import {
  REQUEST_PASSWORD_RESET,
  RESET_PASSWORD,
  RESET_PASSWORD_WITH_TOKEN,
} from '../queries/user.queries';

export const buildResetPassword = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [resetPassword, rest] = useMutation(RESET_PASSWORD);

  return {
    ...rest,
    resetPassword: (password: string) =>
      resetPassword({ variables: { password } })
  };
}

export const buildRequestPasswordReset = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [requestPasswordReset, rest] = useMutation(REQUEST_PASSWORD_RESET);

  return {
    ...rest,
    requestPasswordReset: (username: string) =>
      requestPasswordReset({ variables: { username } })
  }
};

export const buildResetPasswordWithToken = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [resetPasswordWithToken, rest] = useMutation(RESET_PASSWORD_WITH_TOKEN);

  return {
    ...rest,
    resetPasswordWithToken: (variables: any) =>
      resetPasswordWithToken({ variables })
  };
};
