import { defineMessages } from 'react-intl';

export const scope = 'app.components.CreateActivityForm';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'New Activity',
  },
  labelType: {
    id: `${scope}.labelType`,
    defaultMessage: 'Activity Type',
  },
  labelWALKING: {
    id: `${scope}.labelWALKING`,
    defaultMessage: 'Walking',
  },
  labelRUNNING: {
    id: `${scope}.labelRUNNING`,
    defaultMessage: 'Running',
  },
  labelBIKING: {
    id: `${scope}.labelBIKING`,
    defaultMessage: 'Biking',
  },
  labelSWIMMING: {
    id: `${scope}.labelSWIMMING`,
    defaultMessage: 'Swimming',
  },
  labelYOGA: {
    id: `${scope}.labelYOGA`,
    defaultMessage: 'Yoga',
  },
  labelHIKING: {
    id: `${scope}.labelHIKING`,
    defaultMessage: 'Hiking'
  },
  labelEXERCISE: {
    id: `${scope}.labelEXERCISE`,
    defaultMessage: 'Exercise'
  },
  labelDuration: {
    id: `${scope}.labelDuration`,
    defaultMessage: 'Duration',
  },
  labelDate: {
    id: `${scope}.labelDate`,
    defaultMessage: 'Date',
  },
  labelTimeOfDay: {
    id: `${scope}.labelTimeOfDay`,
    defaultMessage: 'Time of Day',
  },
  labelMORNING: {
    id: `${scope}.labelMORNING`,
    defaultMessage: 'Morning',
  },
  labelAFTERNOON: {
    id: `${scope}.labelAFTERNOON`,
    defaultMessage: 'Afternoon',
  },
  labelEVENING: {
    id: `${scope}.labelEVENING`,
    defaultMessage: 'Evening',
  },
  labelNIGHT: {
    id: `${scope}.labelNIGHT`,
    defaultMessage: 'Night',
  },
  labelShare: {
    id: `${scope}.labelShare`,
    defaultMessage: 'Share with Hans Kai group',
  },
  actionCreate: {
    id: `${scope}.actionCreate`,
    defaultMessage: 'Add Activity',
  },
  actionCancel: {
    id: `${scope}.actionCancel`,
    defaultMessage: 'Cancel',
  },
});
