import _ from 'lodash';
import React from 'react';
import { FormattedMessage, createIntl, createIntlCache, RawIntlProvider } from 'react-intl';
import dayjs from 'dayjs';
import styled from 'styled-components';

// Material UI
import Box from '@material-ui/core/Box';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Button from '@material-ui/core/Button';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

// Components
import ColourPrimaryButton from '../../misc/ColourPrimaryButton/colour-primary-button.component';
import { NwFormControl, NwFormInput, NwFormLabel, NwRadioActive, NwRadioInactive, NwCheckbox, NwRowRadioLabel, NwTextarea, NwDatePicker } from '../../misc/Form/form.components';

// Content
import { CreateMealFormProps } from './create-meal-form.interfaces';
import messages from './create-meal-form.messages';
import { DATE_FORMAT, MEAL_TYPES } from '../../../lib/constants';
import withStyles from '@material-ui/core/styles/withStyles';

const Title = styled.h1`
  color: rgba(0, 0, 0, 0.56);
  text-transform: uppercase;
  margin-top: 0;
`;

const ShareLabel = withStyles({
  root: {
    textTransform: 'uppercase',
    padding: '8px 12px',
    background: '#fff',
    border: '1px solid #ddd',
    borderRadius: '4px',
    margin: '16px 0 0',
    color: 'rgba(0, 0, 0, 0.56)',
    '& .MuiFormControlLabel-label': {
      position: 'relative',
      top: '1px',
      fontSize: '14px'
    }
  },
})(FormControlLabel);


interface IsShownProps {
  isShown?: boolean;
}

const SubmitBox = styled.div<IsShownProps>`
  display: flex;
  padding: 16px 16px max(16px, calc(env(safe-area-inset-bottom) + 16px));
  position: fixed;
  bottom: ${props => props.isShown ? 0 : '-600px'};
  width: 100%;
  left: 0;
  background: #fff;
  border-top: 1px solid #ddd;
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
`;

const CreateMealForm: React.FC<CreateMealFormProps> = ({ isShown, isEditing, formik, onCancel }) => {
  const {
    handleSubmit,
    handleBlur,
    handleChange,
    isSubmitting,
    errors,
    touched,
    values,
    setFieldValue
  } = formik;

  const cache = createIntlCache();
  // @ts-ignore
  const intl = createIntl({ locale: 'en', messages }, cache);

  return (
    <form style={{ height: '100%' }} onSubmit={handleSubmit}>
      <RawIntlProvider value={intl}>
        <Box display="flex" flexDirection="column" height="100%" mb={6} style={{ paddingTop: 'env(safe-area-inset-top)' }}>
          <Title>{isEditing ? 'Update Meal' : 'New Meal'}</Title>

          {/* Name */}
          <NwFormControl component="div" error={!!errors.name && touched.name}>
            <NwFormLabel htmlFor="create_meal__name">
              <FormattedMessage {...messages.labelName} />
            </NwFormLabel>
            <NwFormInput
              id="create_meal__name"
              name="name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              aria-describedby="create_meal__name__error_text"
              autoComplete="off"
            />
            {_.isString(errors.name) && touched.name && (
              <FormHelperText id="create_meal__name__error_text">
                {errors.name}
              </FormHelperText>
            )}
          </NwFormControl>

          {/* Description */}
          <NwFormControl component="div" error={!!errors.description && touched.description}>
            <NwFormLabel htmlFor="create_meal__description">
              <FormattedMessage {...messages.labelDescription} />
            </NwFormLabel>
            <NwTextarea
              id="create_meal__description"
              name="description"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.description}
              aria-describedby="create_meal__description__error_text"
              autoComplete="off"
            />
            {_.isString(errors.description) && touched.description && (
              <FormHelperText id="create_meal__description__error_text">
                {errors.description}
              </FormHelperText>
            )}
          </NwFormControl>

          {/* Date */}
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <NwDatePicker
              margin="normal"
              id="date-picker-dialog"
              label="Date"
              format="yyyy-MM-dd"
              value={dayjs(values.date).toDate()}
              onChange={date => { setFieldValue('date', dayjs(date || '').format(DATE_FORMAT)); }}
            />
          </MuiPickersUtilsProvider>

          {/* Type */}
          <NwFormControl component="fieldset">
            <RadioGroup aria-label="time" name="type" value={values.type} onChange={handleChange} row>
              {_.map(MEAL_TYPES, type => {
                // @ts-ignore
                const label = intl.formatMessage(messages[`label${type}`]);

                return (
                  <NwRowRadioLabel
                    key={type}
                    value={type}
                    control={<Radio icon={<NwRadioInactive fontSize="large" />} checkedIcon={<NwRadioActive fontSize="large" />} />}
                    labelPlacement="bottom"
                    label={label}
                  />
                );
              })}
            </RadioGroup>
          </NwFormControl>
          {/*<Divider />*/}
          <NwFormControl component="div">
            <ShareLabel
              control={<NwCheckbox checked={values.isPublic} size="medium" onChange={handleChange} name="isPublic" />}
              label={intl.formatMessage(messages.labelShare)}
            />
          </NwFormControl>
        </Box>
        <SubmitBox isShown={isShown}>
          <Button onClick={onCancel}>
            <FormattedMessage {...messages.actionCancel} />
          </Button>
          <div style={{ flexGrow: 1 }} />
          <ColourPrimaryButton type="submit" disabled={isSubmitting} variant="contained">
            {isEditing ? 'Save Meal' : 'Add Meal'}
          </ColourPrimaryButton>
        </SubmitBox>
      </RawIntlProvider>
    </form>
  );
};

export default CreateMealForm;
