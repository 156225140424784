import React from 'react';
import styled from 'styled-components';

// Material UI
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

// Data
import logo from '../../../assets/logo.png';
import logo2x from '../../../assets/logo@2x.png';
import { SUPPORT_EMAIL } from '../../../lib/constants';
import { buildSignOut } from '../../../lib/services/auth.service';

// Components
import ColourPrimaryButton from '../../misc/ColourPrimaryButton/colour-primary-button.component';

const Logo = styled.img`
  margin: 40px 0;
  width: 400px;
`;

const Text = styled.p`
  color: rgba(0, 0, 0, 0.56);
  text-align: center;
`;

const NoGroupsPage = () => {
  const onSignOut: ClickHandler = buildSignOut();

  return (
    <Container style={{background: '#fefefe', height: '100%'}}>
      <Grid container spacing={3} justify="center" alignItems="center">
        <Grid container item xs={12} justify="center" alignItems="center">
          <Logo srcSet={`${logo} 1x, ${logo2x} 2x`} alt="NorWest Coop"/>
        </Grid>
        <Grid item xs={12}>
          <Text>You do not belong to any Hans Kai groups</Text>
          <Text>Please contact {SUPPORT_EMAIL}</Text>
          <Box mt={2}>
            <ColourPrimaryButton variant="contained" onClick={onSignOut}>Sign Out</ColourPrimaryButton>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

export default NoGroupsPage;
