import _ from 'lodash';
import React from 'react';
import { FormikProps } from 'formik';
import { FormattedMessage } from 'react-intl';
import NumberFormat from 'react-number-format';

// Material UI
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';

// Component
import messages from './member-invite.messages';
import { NwFormInput, NwFormLabel } from '../../misc/Form/form.components';
import Typography from '@material-ui/core/Typography';

interface InviteMemberFormValues {
  email?: string | undefined | null;
  phone?: string | undefined | null;
}

interface MemberInviteProps {
  formik: FormikProps<InviteMemberFormValues>;
  submitError: string | null;
  showSaved: boolean;
}

const MemberInvite: React.FC<MemberInviteProps> = ({ formik, submitError, showSaved }) => {
  const {
    handleSubmit,
    handleBlur,
    handleChange,
    isSubmitting,
    errors,
    touched,
  } = formik;

  return (
    <form onSubmit={handleSubmit}>
      <Box display="flex" flexDirection="column">
        <Typography gutterBottom variant="h6">Send an Invite</Typography>
        {/* Email */}
        <FormControl component="div" error={!!errors.email && touched.email}>
          <NwFormLabel>Email</NwFormLabel>
          <NwFormInput
            id="invite_member__email"
            type="email"
            name="email"
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="off"
          />
        </FormControl>
          <Box my={2}><Typography>OR</Typography></Box>
        <FormControl component="div" error={!!errors.phone && touched.phone}>
          <NwFormLabel>Phone</NwFormLabel>
          <NumberFormat
            style={{ width: '100%', flexGrow: 1 }}
            id="invite_member__phone"
            name="phone"
            onChange={handleChange}
            onBlur={handleBlur}
            customInput={NwFormInput}
          />
          {_.isString(errors.email) && touched.email && (
            <FormHelperText id="invite_member__email__error_text">
              {errors.email}
            </FormHelperText>
          )}
        </FormControl>

        <Box my={2}>
          <Button type="submit" disabled={isSubmitting} variant="contained">
            <FormattedMessage {...messages.actionInvite} />
          </Button>
        </Box>
        {submitError && <FormHelperText error>{submitError}</FormHelperText>}
        {showSaved && <FormHelperText>Invite Sent</FormHelperText>}
      </Box>
    </form>
  );
};

export default MemberInvite;
